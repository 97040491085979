import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import { NumericFormat } from 'react-number-format';
import Icon from "@mui/material/Icon";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import CreatePayment from 'pages/finance/Payment/Create';

function IndexTable(props) {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [editId, setEditId] = useState(0);

    var typingTimer;
    const [searchKey, setSearchKey] = useState("");

    const tableHeadings = [
        {
            name: t('Payment ID'),
            selector: row =>
                <>
                    {props?.editPopup == true ? (
                        <a
                            id="createBuchhaltungPaymentBtn"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#createBuchhaltungPayment"
                            onClick={() => {
                                setEditId(row?.id)
                            }}
                        >{row?.payment_identity}</a>
                    ) : <NavLink to={'/payments/' + row.id} className="dropdown-item">{row?.payment_identity}</NavLink>}
                </>,
            width: '120px',
            sortable: true,
        },
        {
            name: t('Client Name'),
            selector: row => row.vorname + ' ' + row.name,
            sortable: true,
        },
        {
            name: t('Project Nr.'),
            selector: row => <NavLink to={'/projects/' + row.project_id} className="text-primary">{row.project_id}</NavLink>,
            sortable: true,
        },
        {
            name: t('Payment Status'),
            sortable: true,
            selector: row =>
                row.status == '1' ? (
                    <>
                        <SoftBox display="flex" alignItems="center">
                            <SoftButton variant="outlined" color="success" size="small" iconOnly circular>
                                <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                            </SoftButton>
                            <SoftTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
                                &nbsp;&nbsp; {t('2/2 Getilgt')}
                            </SoftTypography>
                        </SoftBox>
                    </>
                ) : row.status == '0' && row.paid == 0 ? (
                    <>
                        <SoftBox display="flex" alignItems="center">
                            <SoftButton variant="outlined" color="error" size="small" iconOnly circular>
                                <Icon sx={{ fontWeight: "bold" }}>sync</Icon>
                            </SoftButton>
                            <SoftTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
                                &nbsp;&nbsp; {t('Open')}
                            </SoftTypography>
                        </SoftBox>
                    </>
                ) : row.status == '0' && row.paid > 0 ? (
                    <>
                        <SoftBox display="flex" alignItems="center">
                            <SoftButton variant="outlined" color="warning" size="small" iconOnly circular>
                                <Icon sx={{ fontWeight: "bold" }}>done</Icon>
                            </SoftButton>
                            <SoftTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
                                &nbsp;&nbsp; {t('1/2 Getilgt')}
                            </SoftTypography>
                        </SoftBox>
                    </>
                ) : ''
        },
        {
            name: t('Lieferdatum Speicher'),
            width: '12rem',
            selector: row => (row?.project?.purchase?.siefertermin_speicher ? dateFormat(row?.project?.purchase?.siefertermin_speicher, "dd.mm.yyyy") : '--'),
        },
        {
            name: t('Forderungen'),
            sortable: true,
            selector: row => (
                <>
                    <NumericFormat
                        value={Number(row?.project?.quations?.order?.netto)}
                        displayType="text"
                        thousandSeparator={"."}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={row.currency == 'EUR' ? ' € ' : ' $ '}
                    />
                </>
            ),
        },
        {
            name: t('Getilgt'),
            selector: row => (
                <>
                    <NumericFormat
                        value={Number(row.paid)}
                        displayType="text"
                        thousandSeparator={"."}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={row.currency == 'EUR' ? ' € ' : ' $ '}
                    />
                </>
            ),
            sortable: true,
        },
        {
            name: t('Actions'),
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("IU") !== -1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("ID") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {JSON.parse(Cookies.get('permissions')).indexOf("IU") !== -1 ? (
                                    <>
                                        <li>
                                            <NavLink to={'/payments/' + row.id} className="dropdown-item">
                                                {t('Add Payment')}
                                            </NavLink>
                                        </li>

                                    </>
                                ) : <></>}

                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {

        var projectId = '';
        if (props?.projectId && props?.projectId > 0) {
            projectId = props?.projectId;
        }

        callFetch("fetch-payments/" + props?.filter + "?page=" + pageNumber + '&search=' + searchKey + '&projectId=' + projectId, "GET", []).then((res) => {
            setTableData(res.data);
        });

    }, [pageNumber, refresh, props?.filter, searchKey, props?.projectId]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>

                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return (
        <>
            <DataTable
                columns={tableHeadings}
                data={tabledata?.data}
                noDataComponent={t('There are no records to display')}
                className='data-table'
                pagination
                highlightOnHover
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationServer
                paginationTotalRows={tabledata?.total}
                onChangePage={handlePageChange}
                paginationComponent={BootyPagination}
                subHeader
                subHeaderComponent={
                    <>
                        {props?.canCreatePayment ? (
                            <button
                                type="button"
                                className="btn btn-primary m-0 float-start"
                                onClick={() => {
                                    document.getElementById('createBuchhaltungPaymentBtn').click();
                                }}
                                style={{ background: '#CB0C9F' }}
                            >{t('Add Payment')}</button>
                        ) : ''}

                        {props?.filterOptions !== false ? (
                            <input
                                type="text"
                                placeholder={t("Search...")}
                                className=' form-control w-sm-50 w-md-25 w-lg-15 ms-2 mt-1'
                                defaultValue={searchKey}
                                /*
                                onChange={(e)=> {
                                    setSearchKey(e.target.value);
                                }}
                                */
                                onKeyUp={(e) => {
                                    clearTimeout(typingTimer);
                                    typingTimer = setTimeout(() => {
                                        setTableData([]);
                                        setSearchKey(e.target.value);
                                    }, 1500);
                                }}
                                onKeyDown={(e) => {
                                    clearTimeout(typingTimer);
                                }}
                            />
                        ) : ''}
                    </>
                }
            />
            <button id='ZahlungseingangeListRefresh' onClick={() => { setRefresh(refresh + 1) }} className='d-none'>ZahlungseingangeListRefresh</button>

            <div className="modal fade" id="createBuchhaltungPayment" tabindex="-1" role="dialog" aria-labelledby="createBuchhaltungPaymentLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h6 className="modal-title" id="createBuchhaltungPaymentLabel" style={{ fontWeight: 'bold' }}></h6>
                            <button
                                type="button"
                                id="createBuchhaltungPaymentClose"
                                className="btn-close text-dark"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            {editId ? (
                                <CreatePayment
                                    id={editId}
                                    //orderId={data?.quations?.order?.id ? data?.quations?.order?.id : 0}
                                    // col="col-md-12"
                                    redirect={false}
                                    popupClose={true}
                                    //invoiceListRefresh={true}
                                    ZahlungseingangeListRefresh={true}
                                />
                            ) : ''}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IndexTable;

import { React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import dateFormat, { masks } from "dateformat";
import { NumericFormat } from 'react-number-format';
import callFetch from "../../helpers/callFetch";
import CorrectionRequestView from "../project/create/CorrectionRequestView";
import Reklamation from "pages/project/create/timeline/Reklamation";
import ProjectFeedBack from "pages/project/create/timeline/ProjectFeedBack";
import SoftTypography from "components/SoftTypography";
import { NavLink } from "react-router-dom";

function Timeline(props) {
    const { t } = useTranslation();
    let params = useParams();
    const [refresh, setRefresh] = useState(1);
    const [data, setData] = useState([]);
    var AppointmentSerial = 1;
    var QuotationSerial = 1;
    var Orderserial = 1;
    var serial = 1;
    const [projectFeedback, setProjectFeedback] = useState([]);

    useEffect(() => {
        if (!props.projectid) return;
        callFetch("project/timelines/" + (props?.projectid ? props?.projectid : 0), "GET", []).then((res) => {
            setData(res.data);
        });
    }, [props?.projectid + props?.refresh + (props?.refresh ?? 0)]);

    return (
        <>
            {data && data.project ? (
                <div className="">
                    <div className="">
                        <div className="">
                        <div className="timeline timeline-one-side" data-timeline-axis-style="dotted">

{data?.project?.fertigmeldung[0] ? (
    <div className="timeline-block mb-3">
        <span className="timeline-step">
            <i className="ni ni-bell-55 text-success text-gradient" />
        </span>
        <div className="timeline-content">
            <h6 className="text-dark text-sm font-weight-bold mb-0">{'Fertigmeldung'}</h6>
            <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(data?.project?.fertigmeldung[0].created_at, "dd.mm.yyyy")}</p>

            <p className="text-sm m-0">
                <SoftTypography variant="caption"><strong>{t('Fertigmeldung')}: </strong></SoftTypography>
                <SoftTypography variant="caption">{data?.project?.fertigmeldungErfolgreich == 0 ? t('Approve') : t('Approve')}</SoftTypography>
            </p>

            <p className="text-sm m-0">
                <SoftTypography variant="caption"><strong>{t('Status')}: </strong></SoftTypography>
                <SoftTypography
                    variant="caption"
                    color={data?.project?.fertigmeldungErfolgreich == 1 ? 'success' : ''}
                >
                    {data?.project?.fertigmeldungErfolgreich == 1 ? t('Erfolgreich') : t('In Berbeitung')}
                </SoftTypography>
            </p>

            <p className="text-sm m-0">
                <SoftTypography variant="caption"><strong>{t('Zählertermin')}: </strong></SoftTypography>
                <SoftTypography variant="caption">{data?.project?.aczahlertermin?.date ? dateFormat(data?.project?.aczahlertermin?.date, "dd.mm.yyyy") + ' ' + (data?.project?.aczahlertermin?.time ? data?.project?.aczahlertermin?.time : '') + ' Uhr' : ''}</SoftTypography>
            </p>

            <p className="text-sm m-0">
                <SoftTypography variant="caption"><strong>{t('Techniker')}: </strong></SoftTypography>
                <SoftTypography variant="caption">{data?.project?.aczahlertermin_plan?.employee?.name ? data?.project?.aczahlertermin_plan?.employee?.name : ''}</SoftTypography>
            </p>

            <p className="text-sm m-0">
                <SoftTypography variant="caption"><strong>{t('Termin')}: </strong></SoftTypography>
                <SoftTypography variant="caption">{data?.project?.aczahlertermin_plan?.date ? dateFormat(data?.project?.aczahlertermin_plan?.date, "dd.mm.yyyy") + ' ' + (data?.project?.aczahlertermin_plan?.time ? data?.project?.aczahlertermin_plan?.time : '') + ' Uhr' : ''}</SoftTypography>
            </p>

            <p className="text-sm m-0 mb-0">
                <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                <SoftTypography variant="caption">{dateFormat(data?.project?.fertigmeldung[0].created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
            </p>
            <p className="text-sm m-0 mb-1">
                <SoftTypography variant="caption">{'fertigmeldung@solacloud.de'}</SoftTypography>
            </p>
        </div>
    </div>
) : ''}


{data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
    <>
        {reclamation?.department == 'Elektromontage' ? (
            <>
                <Reklamation reclamation={reclamation} key={index} acStatusEdit={props?.acStatusEdit ? props?.acStatusEdit : false} />
            </>
        ) : ''}
    </>
))}


{data.assign_orders && data.assign_orders.map((assign_order) => (
    <>
        {assign_order.dachmontag_elektromontage == 'Elektromontage' ? (
            <div className="timeline-block mb-3">
                <span className="timeline-step">
                    <i className="ni ni-bell-55 text-success text-gradient" />
                </span>
                <div className="timeline-content">
                    <h6 className="text-dark text-sm font-weight-bold mb-0">{assign_order.dachmontag_elektromontage}</h6>
                    <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order.created_at, "dd.mm.yyyy")}</p>
                    <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order.created_at, "HH::ss")}</p>

                    <p className="text-sm m-0">
                        <SoftTypography variant="caption"><strong>{t('Techniker')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order?.employee?.name}</SoftTypography>
                    </p>

                    <p className="text-sm m-0">
                        <SoftTypography variant="caption"><strong>{t('Termin')}: </strong></SoftTypography>
                        <SoftTypography variant="caption">{assign_order?.date ? dateFormat(assign_order?.date, "dd.mm.yyyy") + ' ' + (assign_order?.time ? assign_order?.time : '') + ' Uhr' : ''}</SoftTypography>
                    </p>

                    {/*
                    <p className="text-sm m-0">
                        <SoftTypography variant="caption"><strong>{t('Stadium')}: </strong></SoftTypography>
                        <SoftTypography variant="caption" color={assign_order?.acabnameprotokoll?.comisioning_status == "1" ? 'success' : ''}>{assign_order?.acabnameprotokoll?.comisioning_status == "1" ? 'Successfull' : "Not Successfull"}</SoftTypography>
                    </p>
                    */}

                    <p className="text-sm m-0">
                        <SoftTypography variant="caption"><strong>{t('Stadium')}: </strong></SoftTypography>
                        <SoftTypography
                            sx={{
                                fontWeight: '500'
                            }}
                            variant="caption"
                            color={assign_order?.acabnameprotokoll?.comisioning_status == "1" ? 'success' : ''}>{assign_order?.acabnameprotokoll?.comisioning_status == "1" ? t('Successfull') : assign_order?.acabnameprotokoll?.comisioning_status == "2" ? t("Not Successfull") : 'Nicht bewertet'}</SoftTypography>
                        {assign_order?.acabnameprotokoll?.id && props?.acStatusEdit ? (
                            <i
                                className="fa fa-pencil ms-2"
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    document.getElementById('ComisioningModalOpen').click();
                                }}
                            ></i>
                        ) : ''}

                    </p>
                    <p className="text-sm mb-1">
                        <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order?.acabnameprotokoll?.comisioning_status == "1" ? <a target={'_blank'} href={process.env.REACT_APP_BACKEND_URL + 'acabname/' + assign_order?.acabnameprotokoll?.id}>AC Abnahmeprotokoll PDF</a> : "--"}</SoftTypography>
                    </p>

                    <p className="text-sm mb-1">
                        <SoftTypography variant="caption"><strong>{t('Eingangsrechnung')}: </strong></SoftTypography>
                        <SoftTypography variant="caption">
                            {data.project.incoming_invoces && data.project.incoming_invoces.map((incoming_invoce) => (
                                <>
                                    {incoming_invoce?.abteilung == 'Elektromontage' ? (
                                        <NavLink className={'ms-1'} to={'/eingangsrechnungen/' + incoming_invoce?.eingangs_rechungs_nummer}>{'ER-' + incoming_invoce?.eingangs_rechungs_nummer}</NavLink>
                                    ) : ''}
                                </>
                            ))}
                        </SoftTypography>
                    </p>

                    {data.project.corrections && data.project.corrections.map((correction) => (
                        <>
                            {correction.related == 'auftrag' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={Orderserial++}></CorrectionRequestView> : ''}
                        </>
                    ))}

                    {assign_order.email_trans && assign_order.email_trans.map((email_tran) => (
                        <>
                            {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                <>
                                    <p className="text-sm m-0 mb-1">
                                        <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                        <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                    </p>
                                    <p className="text-sm m-0 mb-1">
                                        <SoftTypography variant="caption">{email}</SoftTypography>
                                    </p>
                                </>
                            ))}
                        </>
                    ))}
                </div>
            </div>
        ) : ''
        }
    </>
))}


{data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
    <>
        {reclamation?.department == 'Buchhaltung' ? (
            <>
                <Reklamation reclamation={reclamation} key={index} />
            </>
        ) : ''}
    </>
))}



{data.invoices && data.invoices.map((invoice, invoice_serial) => (
    <>
        <div className="timeline-block mb-3">
            <span className="timeline-step">
                <i className="ni ni-bell-55 text-success text-gradient" />
            </span>
            <div className="timeline-content">
                <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Invoice') + ' ' + JSON.parse(invoice?.product_details)?.amountPerchantage + '%'}</h6>
                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(invoice?.created_at, "dd.mm.yyyy")}</p>

                <p className="text-sm mt-1 mb-0">
                    <SoftTypography variant="caption"><strong>{t('InvoiceNr.')}: </strong></SoftTypography> <SoftTypography variant="caption">{invoice?.id}</SoftTypography>
                </p>
                <p className="text-sm mt-0 mb-1"><SoftTypography variant="caption"><strong>{t('Payment')}: </strong></SoftTypography> {
                    invoice?.payment_items[0]?.paid_ammount >= JSON.parse(invoice?.product_details)?.amount ? (
                        <>
                            <SoftTypography variant="caption" style={{ color: ((invoice_serial + 1) + '/2 Getilgt') == '1/2 Getilgt' ? '#d2a400' : 'success' }} fontWeight="medium" color={((invoice_serial + 1) + '/2 Getilgt') == '1/2 Getilgt' ? 'warning' : 'success'} sx={{ lineHeight: 0 }}>
                                {t((invoice_serial + 1) + '/2 Getilgt')}
                            </SoftTypography>
                        </>
                    )
                        : (
                            <>
                                <SoftTypography variant="caption" fontWeight="medium" color="error" sx={{ lineHeight: 0 }}>
                                    {t('Open')}
                                </SoftTypography>
                            </>
                        )
                }</p>
            </div>
        </div>
    </>
))}



{data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
    <>
        {reclamation?.department == 'Einkauf' ? (
            <>
                <Reklamation reclamation={reclamation} key={index} />
            </>
        ) : ''}
    </>
))}




{data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
    <>
        {reclamation?.department == 'Dachmontage' ? (
            <>
                <Reklamation reclamation={reclamation} key={index}  dcStatusEdit={props?.dcStatusEdit ? props?.dcStatusEdit : false}/>
            </>
        ) : ''}
    </>
))}

{data?.project?.purchase ? (
    <div className="timeline-block mb-3">
        <span className="timeline-step">
            <i className="ni ni-bell-55 text-success text-gradient" />
        </span>
        <div className="timeline-content">
            <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Beschaffung')}</h6>
            <p className="text-sm mt-1 mb-0">
                <SoftTypography variant="caption"><strong>{t('Liefertermin Modul')}: </strong></SoftTypography>
                <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.liefertermin_module ? dateFormat(data?.project?.purchase?.liefertermin_module, "dd.mm.yyyy") : '---'}</SoftTypography><br />
                <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.liefertermin_module ? data?.project?.quations?.anzahl_module + ' Stck. | ' + (data?.project?.quations?.module_eingeben == 'no' ? data?.project?.quations?.module?.name : data?.project?.quations?.module_name) : '---'}</SoftTypography>
            </p>
            <p className="text-sm mt-1 mb-0">
                <SoftTypography variant="caption"><strong>{t('Liefertermin Speicher')}: </strong></SoftTypography>
                <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.siefertermin_speicher ? dateFormat(data?.project?.purchase?.siefertermin_speicher, "dd.mm.yyyy") : '---'}</SoftTypography><br />
                <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.siefertermin_speicher ? (data?.project?.quations?.speicher_eingeben == 'no' ? data?.project?.quations?.speichers?.name : data?.project?.quations?.speicher_titel) : '---'}</SoftTypography>
            </p>
            <p className="text-sm mt-1 mb-0">
                <SoftTypography variant="caption"><strong>{t('DC Liefertermin Großhändler')}: </strong></SoftTypography> <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.liefertermin_grobhandler ? dateFormat(data?.project?.purchase?.liefertermin_grobhandler, "dd.mm.yyyy") : '---'}</SoftTypography>
            </p>

            <p className="text-sm mt-1 mb-0">
                <SoftTypography variant="caption"><strong>{t('AC Liefertermin Großhändler')}: </strong></SoftTypography> <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.ac_liefertermin_grobhandler ? dateFormat(data?.project?.purchase?.ac_liefertermin_grobhandler, "dd.mm.yyyy") : '---'}</SoftTypography>
            </p>
        </div>
    </div>
) : ''}


{data.assign_orders && data.assign_orders.map((assign_order) => (
    <>
        {assign_order.dachmontag_elektromontage == 'Dachmontage' ? (
            <>
                <div className="timeline-block mb-3">
                    <span className="timeline-step">
                        <i className="ni ni-bell-55 text-success text-gradient" />
                    </span>
                    <div className="timeline-content">
                        <h6 className="text-dark text-sm font-weight-bold mb-0">{assign_order.dachmontag_elektromontage}</h6>
                        <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order?.created_at, "dd.mm.yyyy")}</p>
                        <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order?.created_at, "HH::ss")}</p>

                        <p className="text-sm m-0">
                            <SoftTypography variant="caption"><strong>{t('Techniker')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order?.employee?.name ? assign_order?.employee?.name : ''}</SoftTypography>
                        </p>

                        <p className="text-sm m-0">
                            <SoftTypography variant="caption"><strong>{t('Termin')}: </strong></SoftTypography>
                            <SoftTypography variant="caption">{assign_order?.date ? dateFormat(assign_order?.date, "dd.mm.yyyy") + ' ' + assign_order?.time + ' Uhr' : ''}</SoftTypography>
                        </p>

                        <p className="text-sm m-0">
                            <SoftTypography variant="caption"><strong>{t('Stadium')}: </strong></SoftTypography>
                            <SoftTypography
                                sx={{
                                    fontWeight: '500'
                                }}
                                variant="caption"
                                color={assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? 'success' : ''}>{assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? t('Successfull') : assign_order?.dcabnameprotokoll?.comisioning_status == "2" ? t("Not Successfull") : 'Nicht bewertet'}</SoftTypography>
                            {assign_order?.dcabnameprotokoll?.id && props?.dcStatusEdit ? (
                                <i
                                    className="fa fa-pencil ms-2"
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        document.getElementById('ComisioningModalOpen').click();
                                    }}
                                ></i>
                            ) : ''}

                        </p>
                        <p className="text-sm mb-1">
                            <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? <a target={'_blank'} href={process.env.REACT_APP_BACKEND_URL + 'dcabname/' + assign_order?.dcabnameprotokoll?.id}>DC Abnahmeprotokoll PDF</a> : "--"}</SoftTypography>
                        </p>

                        <p className="text-sm mb-1">
                            <SoftTypography variant="caption"><strong>{t('Eingangsrechnung')}: </strong></SoftTypography>
                            <SoftTypography variant="caption">
                                {data.project.incoming_invoces && data.project.incoming_invoces.map((incoming_invoce) => (
                                    <>
                                        {incoming_invoce?.abteilung == 'Dachmontage' ? (
                                            <NavLink className={'ms-1'} to={'/eingangsrechnungen/' + incoming_invoce?.eingangs_rechungs_nummer}>{'ER-' + incoming_invoce?.eingangs_rechungs_nummer}</NavLink>
                                        ) : ''}
                                    </>
                                ))}
                            </SoftTypography>
                        </p>

                        {data.project.corrections && data.project.corrections.map((correction) => (
                            <>
                                {correction.related == 'auftrag' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={Orderserial++}></CorrectionRequestView> : ''}
                            </>
                        ))}

                        {assign_order.email_trans && assign_order.email_trans.map((email_tran) => (
                            <>
                                {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                    <>
                                        <p className="text-sm m-0 mb-1">
                                            <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                            <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                        </p>
                                        <p className="text-sm m-0 mb-1">
                                            <SoftTypography variant="caption">{email}</SoftTypography>
                                        </p>
                                    </>
                                ))}
                            </>
                        ))}
                    </div>
                </div>
            </>
        ) : ''
        }
    </>
))}

{data.orders && data.orders.map((order) => (
    <>
        <div className="timeline-block mb-3">
            <span className="timeline-step">
                <i className="ni ni-bell-55 text-success text-gradient" />
            </span>
            <div className="timeline-content">
                <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Order')}</h6>
                <p className="text-secondary font-weight-bold text-xs mt-1">{dateFormat(order.created_at, "dd.mm.yyyy")}</p>


                <p className="text-sm m-0">
                    <SoftTypography variant="caption"><strong>{t('Order Nr.')}: </strong></SoftTypography> <SoftTypography variant="caption">{order.id}</SoftTypography>
                </p>
                {JSON.parse(Cookies.get('permissions')).indexOf("QR") !== -1 ?
                    <p className="text-sm m-0 mb-1">
                        <SoftTypography variant="caption"><strong>{t('Price')} Netto: </strong></SoftTypography> <SoftTypography variant="caption"><NumericFormat value={order.netto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} />€</SoftTypography>
                    </p>
                    : ''}

                {data.project.corrections && data.project.corrections.map((correction) => (
                    <>
                        {correction.related == 'Order' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={serial++}></CorrectionRequestView> : ''}
                    </>
                ))}

                <p className="text-sm m-0">
                    <SoftTypography variant="caption"><strong>{t('Wunschtermin vorhanden')}: </strong></SoftTypography> <SoftTypography variant="caption">{/*dateFormat(data?.project?.desired_assembly, "dd.mm.yyyy")*/ data?.project?.desired_assembly}</SoftTypography>
                </p>

                {order.email_trans && order.email_trans.map((email_tran) => (
                    <>

                        {email_tran?.email && email_tran?.email.split(',').map((email) => (
                            <>
                                <p className="text-sm m-0 mb-1">
                                    <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                    <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                </p>
                                <p className="text-sm m-0 mb-1">
                                    <SoftTypography variant="caption">{email}</SoftTypography>
                                </p>
                            </>
                        ))}
                    </>
                ))}
            </div>
        </div>
    </>
))}

{data.quations && data.quations.map((quation) => (
    <>
        <div className="timeline-block mb-3">
            <span className="timeline-step">
                <i className="ni ni-bell-55 text-success text-gradient" />
            </span>
            <div className="timeline-content">
                <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Quotation')} {quation?.resale == 1 ? '(Resale)' : ''}</h6>
                <p className="text-secondary font-weight-bold text-xs mt-1">{dateFormat(quation.created_at, "dd.mm.yyyy")}</p>

                <p className="text-sm m-0">
                    <SoftTypography variant="caption"><strong>{t('Quotation Nr.')}: </strong></SoftTypography> <SoftTypography variant="caption">{quation.id}</SoftTypography>
                </p>

                {JSON.parse(Cookies.get('permissions')).indexOf("QR") !== -1 ?
                    <p className="text-sm m-0 mb-1">
                        <SoftTypography variant="caption"><strong>{t('Price')} Netto: </strong></SoftTypography>
                        <SoftTypography variant="caption">
                            <NumericFormat value={quation.gesami_netto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} />€
                        </SoftTypography>
                    </p>
                    : ''}

                <p className="text-sm m-0">
                    <SoftTypography variant="caption"><strong>{t('VKB Name')}: </strong></SoftTypography> <SoftTypography variant="caption">{quation?.user?.name}</SoftTypography>
                </p>

                {data.project.corrections && data.project.corrections.map((correction) => (
                    <>
                        {correction.related == 'Quotation' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={QuotationSerial++}></CorrectionRequestView> : ''}
                    </>
                ))}

                {quation.email_trans && quation.email_trans.map((email_tran) => (
                    <>

                        {email_tran?.email && email_tran?.email.split(',').map((email) => (
                            <>
                                <p className="text-sm m-0 mb-1">
                                    <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                    <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                </p>
                                <p className="text-sm m-0 mb-1">
                                    <SoftTypography variant="caption">{email}</SoftTypography>
                                </p>
                            </>
                        ))}
                    </>
                ))}
            </div>
        </div>
    </>
))}


<div className="timeline-block mb-3">
    <span className="timeline-step">
        <i className="ni ni-bell-55 text-success text-gradient" />
    </span>
    <div className="timeline-content">
        <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Appointment')}</h6>
        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">{dateFormat(data.project.date, "dd.mm.yyyy")}</p>

        <p className="text-sm mt-3 mb-2">{t('Client Information')}:</p>
        <p className="text-sm m-0">
            <SoftTypography variant="caption"><strong>{t('Name')}: </strong></SoftTypography> <SoftTypography variant="caption">{data.project && data.project != undefined ? data.project.vorname + ' ' + data.project.name : ''}</SoftTypography>
        </p>
        <p className="text-sm m-0">
            <SoftTypography variant="caption"><strong>{t('Straße')}: </strong></SoftTypography> <SoftTypography variant="caption">{data.project && data.project != undefined ? (data.project.street + ' ' + data.project.nr) : ''}</SoftTypography>
        </p>
        <p className="text-sm m-0">
            <SoftTypography variant="caption"><strong>{t('PLZ und Ort')}: </strong></SoftTypography> <SoftTypography variant="caption">{data.project && data.project != undefined ? data.project.plz + ' ' + data.project.ort : ''}</SoftTypography>
        </p>

        {JSON.parse(Cookies.get('permissions')).indexOf("CanSCIn") !== -1 ? (
            <p className="text-sm m-0 mb-1">
                <SoftTypography variant="caption"><strong>{t('Phone')}: </strong></SoftTypography> <SoftTypography variant="caption">{data.project && data.project != undefined ? data.project.telefonnummer : ''}</SoftTypography>
            </p>
        ) : ''}

        {data.project.status_activity && data.project.status_activity.map((activity, index) => {
            return <>
                <p className="text-sm m-0">
                    <SoftTypography variant="caption"><strong>{t('Status')}: </strong></SoftTypography>
                    <SoftTypography variant="caption">

                        {activity?.project_feedback ? (
                            <>
                                <a className="text-primary"
                                    onClick={() => {
                                        setProjectFeedback(activity?.project_feedback);
                                        document.getElementById('projectStatusWithFileUploadOpenBtnUpdate').click();
                                    }}
                                >{activity?.title}</a>
                            </>
                        ) : activity?.title}

                        {/*activity?.project_feedback?.attachments && JSON.parse(activity?.project_feedback?.attachments).map((activity, index) => {
                            return <><a target="_blank" href={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + activity?.attachment} className="mx-1"><i className="fa-regular fa-image"></i></a></>
                        })*/}
                    </SoftTypography>
                </p>
            </>
        })}


        {data.project.corrections && data.project.corrections.map((correction, index) => {
            return <>
                {correction.related == 'Appointment' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={AppointmentSerial++}></CorrectionRequestView> : ''}
            </>
        })}
    </div>
</div>


</div>
                        </div>
                    </div>
                </div>
            ) : <></>}
        </>
    )
}

export default Timeline;

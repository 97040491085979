import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import Cookies from 'js-cookie';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDropzone from "components/SoftDropzone";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import SoftSnackbar from "components/SoftSnackbar";
// @mui material components
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import EmailForm from "../EmailForm";
import DateForm from "./DateForm";
import Timeline from "../Timeline";

import { NumericFormat } from 'react-number-format';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Overview from '../../Overview';
function getSteps() {
    return ["1. Order", "2. Digital Sign"];
}

function Order(props) {
    let params = useParams();
    const { t } = useTranslation();
    const errorNotify = () => toast("Please create Qoatation first");
    const errorNotify2 = () => toast("Angebot Bundle Deactive");
    const [errorData, setErrorData] = useState(false);
    const [currency, setCurrency] = useState('EUR');
    /*
    const [quationId, setQuationId] = useState(props.quationId);
    const [quation, setQuation] = useState({
        gesami_netto: 0,
        gesami_brutto: 0,
    });
    */

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const [contactPersons, setContactPersons] = useState([]);
    const [data, setData] = useState([]);
    const [emails, setEmails] = useState([{ name: '', email: '', currentEmail: '' }]);
    const [items, setItems] = useState([{ id: 0, title: '', quantity: 0, price: 0, currentPrice: 0, total: 0 }]);
    const [itemSummery, setItemSummery] = useState({ items: [], emails: [], subTotal: 0, discountPercentage: 0, discount: 0, taxPercentage: 20, tax: 0, total: 0 });
    //const [order, setOrder] = useState(0);
    const [saving, setSaving] = useState(false);
    //const [fileUploading, setFileUploading] = useState(false);
    // const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [refresh3, setRefresh3] = useState(0);
    const [refresh4, setRefresh4] = useState(0);
    const [currentAttachmentTab, setCurrentAttachmentTab] = useState('dachansicht');

    const [attachments, setAttachment] = useState([]);
    const [dachansicht, setDachansicht] = useState([]);
    const [dachsparren, setDachsparren] = useState([]);
    const [dachgiebel, setDachgiebel] = useState([]);
    const [gesamtansicht_von_haus_und_dach, setGesamtansicht_von_haus_und_dach] = useState([]);
    const [innendachansicht, setInnendachansicht] = useState([]);
    const [kompletter_zahlerschrank, setKompletter_zahlerschrank] = useState([]);
    const [zahler, setZahler] = useState([]);
    const [wechselrichter_standort, setWechselrichter_standort] = useState([]);
    const [bei_nachust, setBei_nachust] = useState([]);
    const [ziegel_mit_meterstab, setZiegel_mit_meterstab] = useState([]);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm();

    const handeleAttachment = (name, newData) => {
        //clearErrors();
        clearErrors(name);

        if (name == 'dachansicht') {
            let data = dachansicht;
            data[dachansicht.length] = newData;
            setDachansicht(data);
            setValue(name, JSON.stringify(data));
        } else if (name == 'dachsparren') {
            let data = dachsparren;
            data[dachsparren.length] = newData;
            setDachsparren(data);
            setValue(name, JSON.stringify(data));
        } else if (name == 'dachgiebel') {
            let data = dachgiebel;
            data[dachgiebel.length] = newData;
            setDachgiebel(data);
            setValue(name, JSON.stringify(data));
        } else if (name == 'gesamtansicht_von_haus_und_dach') {
            let data = gesamtansicht_von_haus_und_dach;
            data[gesamtansicht_von_haus_und_dach.length] = newData;
            setGesamtansicht_von_haus_und_dach(data);
            setValue(name, JSON.stringify(data));
        } else if (name == 'innendachansicht') {
            let data = innendachansicht;
            data[innendachansicht.length] = newData;
            setInnendachansicht(data);
            setValue(name, JSON.stringify(data));
        } else if (name == 'kompletter_zahlerschrank') {
            let data = kompletter_zahlerschrank;
            data[kompletter_zahlerschrank.length] = newData;
            setKompletter_zahlerschrank(data);
            setValue(name, JSON.stringify(data));
        } else if (name == 'zahler') {
            let data = zahler;
            data[zahler.length] = newData;
            setZahler(data);
            setValue(name, JSON.stringify(data));
        } else if (name == 'wechselrichter_standort') {
            let data = wechselrichter_standort;
            data[wechselrichter_standort.length] = newData;
            setWechselrichter_standort(data);
            setValue(name, JSON.stringify(data));
        } else if (name == 'bei_nachust') {
            let data = bei_nachust;
            data[bei_nachust.length] = newData;
            setBei_nachust(data);
            setValue(name, JSON.stringify(data));
        } else if (name == 'ziegel_mit_meterstab') {
            let data = ziegel_mit_meterstab;
            data[ziegel_mit_meterstab.length] = newData;
            setZiegel_mit_meterstab(data);
            setValue(name, JSON.stringify(data));
        }
    }

    const removeAttachment = (id) => {
        const beforeRemove = eval(currentAttachmentTab);
        const afterRemove = beforeRemove.filter(value => {
            return value.id != id;
        });
        if (currentAttachmentTab == 'dachansicht') {
            setDachansicht(afterRemove);
        } else if (currentAttachmentTab == 'dachsparren') {
            setDachsparren(afterRemove);
        } else if (currentAttachmentTab == 'dachgiebel') {
            setDachgiebel(afterRemove);
        } else if (currentAttachmentTab == 'gesamtansicht_von_haus_und_dach') {
            setGesamtansicht_von_haus_und_dach(afterRemove);
        } else if (currentAttachmentTab == 'innendachansicht') {
            setInnendachansicht(afterRemove);
        } else if (currentAttachmentTab == 'kompletter_zahlerschrank') {
            setKompletter_zahlerschrank(afterRemove);
        } else if (currentAttachmentTab == 'zahler') {
            setZahler(afterRemove);
        } else if (currentAttachmentTab == 'wechselrichter_standort') {
            setWechselrichter_standort(afterRemove);
        } else if (currentAttachmentTab == 'bei_nachust') {
            setBei_nachust(afterRemove);
        } else if (currentAttachmentTab == 'ziegel_mit_meterstab') {
            setZiegel_mit_meterstab(afterRemove);
        }
        setValue(currentAttachmentTab, JSON.stringify(afterRemove));
    }

    useEffect(() => {
        var subTotal = 0;
        items.map((item) => {
            subTotal += item.total;
        });
        itemSummery.subTotal = subTotal;
        itemSummery.tax = parseFloat((subTotal * (itemSummery.taxPercentage / 100)).toFixed(2));
        itemSummery.discount = parseFloat((subTotal * (itemSummery.discountPercentage / 100)).toFixed(2));
        itemSummery.total = subTotal - itemSummery.discount + itemSummery.tax;
        itemSummery.items = items;
        setItemSummery(itemSummery);
        setRefresh2(refresh2 + 1);
    }, [refresh, (props?.projectid ? props?.projectid : params.id)]);

    useEffect(() => { }, [refresh2]);


    useEffect(() => {
        if (!(props?.projectid ? props?.projectid : params.id)) return;

        flatpickr(".flat-pickr");
        callFetch("project/order/" + (props?.projectid ? props?.projectid : params.id) + "/edit", "GET", []).then((res) => {
            setData(res.data);
            return;
        });
    }, [refresh3, (props?.projectid ? props?.projectid : params.id)]);

    useEffect(() => {

        if (!data.id) return;

        if (data?.quations?.order) { //update order
            for (let [key, value] of Object.entries(data?.quations?.order)) {
                if (key == 'order_date') {
                    setValue(key, dateFormat(value, "yyyy-mm-dd"));
                } else if (key == 'netto' || key == 'brutto') {
                    setValue(key, Number(value));
                } else {
                    setValue(key, (value == null || value == 'null' ? "" : value));
                }
            }

            setValue('quation_id', data?.quations?.id);
            setValue('status', 1);

            setDachansicht(data?.quations?.order?.dachansicht && data?.quations?.order?.dachansicht != null && data?.quations?.order?.dachansicht != 'null' ? JSON.parse(data?.quations?.order?.dachansicht) : []);
            setDachsparren(data?.quations?.order?.dachsparren && data?.quations?.order?.dachsparren != null && data?.quations?.order?.dachsparren != 'null' ? JSON.parse(data?.quations?.order?.dachsparren) : []);
            setDachgiebel(data?.quations?.order?.dachgiebel && data?.quations?.order?.dachgiebel != null && data?.quations?.order?.dachgiebel != 'null' ? JSON.parse(data?.quations?.order?.dachgiebel) : []);
            setGesamtansicht_von_haus_und_dach(data?.quations?.order?.gesamtansicht_von_haus_und_dach && data?.quations?.order?.gesamtansicht_von_haus_und_dach != null && data?.quations?.order?.gesamtansicht_von_haus_und_dach != 'null' ? JSON.parse(data?.quations?.order?.gesamtansicht_von_haus_und_dach) : []);
            setInnendachansicht(data?.quations?.order?.innendachansicht && data?.quations?.order?.innendachansicht != null && data?.quations?.order?.innendachansicht != 'null' ? JSON.parse(data?.quations?.order?.innendachansicht) : []);
            setKompletter_zahlerschrank(data?.quations?.order?.kompletter_zahlerschrank && data?.quations?.order?.kompletter_zahlerschrank != null && data?.quations?.order?.kompletter_zahlerschrank != 'null' ? JSON.parse(data?.quations?.order?.kompletter_zahlerschrank) : []);
            setZahler(data?.quations?.order?.zahler && data?.quations?.order?.zahler != null && data?.quations?.order?.zahler != 'null' ? JSON.parse(data?.quations?.order?.zahler) : []);
            setWechselrichter_standort(data?.quations?.order?.wechselrichter_standort && data?.quations?.order?.wechselrichter_standort != null && data?.quations?.order?.wechselrichter_standort != 'null' ? JSON.parse(data?.quations?.order?.wechselrichter_standort) : []);
            setBei_nachust(data?.quations?.order?.bei_nachust && data?.quations?.order?.bei_nachust != null && data?.quations?.order?.bei_nachust != 'null' ? JSON.parse(data?.quations?.order?.bei_nachust) : []);
            setZiegel_mit_meterstab(data?.quations?.order?.ziegel_mit_meterstab && data?.quations?.order?.ziegel_mit_meterstab != null && data?.quations?.order?.ziegel_mit_meterstab != 'null' ? JSON.parse(data?.quations?.order?.ziegel_mit_meterstab) : []);

            let pd = data?.quations?.order?.product_details && data?.quations?.order?.product_details != null && data?.quations?.order?.product_details != 'null' ? JSON.parse(data?.quations?.order?.product_details) : [];
            //let cd = data?.quations?.order?.contact_person  && data?.quations?.order?.contact_person != null && data?.quations?.order?.contact_person != 'null' ? JSON.parse(data?.quations?.order?.customer.contact_person) : [];
            //setContactPersons(cd);
            //setQuationId(data?.quations?.order?.quation_id);
            //setEmails(pd.emails);
            setItems(pd.items);
            setItemSummery(pd);

        } else if (data?.quations && data?.bundleDeactive === false) {

            setValue('quation_id', data?.quations?.id);
            setValue('status', 1);

            if (Number(data?.quations?.netto_brotto) == 1) {  // 1 means netto
                //setQuation(res.data.quation);
                setValue('netto', Number(data?.quations?.gesami_netto));
                setValue('brutto', Number(data?.quations?.gesami_netto));
                setValue('tax', 0); // by default tax will be 0%

            } else if (Number(data?.quations?.netto_brotto) == 0) {  // 0 means brotto
                // setQuation(res.data.quation);
                setValue('netto', Number(data?.quations?.gesami_netto));
                setValue('brutto', Number(data?.quations?.gesami_brutto));
            }
            setRefresh2(refresh2 + 1);
        } else {

            if (data?.bundleDeactive === true) {
                //alert('Angebot bundle deactive')
                errorNotify2();
            }else{
                //alert('Please create Qoatation first')
                errorNotify();
            }
           
            setErrorData(true);

            if (document.getElementById("distribution_li")) {
                document.getElementById("distribution_li").click();
            }

            if (document.getElementById("distribution")) {
                document.getElementById("distribution").click();
            }

            if (document.getElementById("AngeboteSalesTab")) {
                document.getElementById("AngeboteSalesTab").click();
            }
        }

    }, [data, (props?.projectid ? props?.projectid : params.id)]);

    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }

    function deleteEmail() {
        var temails = [];
        emails.map((e) => {
            if (!e && e !== '')
                return;
            temails.push(e);
        });
        setEmails(temails);
        itemSummery.emails = temails;
        setItemSummery(itemSummery);
        setRefresh2(refresh2 + 1);
    }

    const fileUploadTab = (name) => {
        setCurrentAttachmentTab(name);
    }

    const dropZoneInitialize = () => {
        return (
            <SoftDropzone
                options={{
                    dictDefaultMessage: t('Bilder Hochladen'),
                    // addRemoveLinks: true,  
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf,.doc,.docx",
                    action: process.env.REACT_APP_API_URL + 'attachment',
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer " + Cookies.get('token')
                    },
                    processing: function () {
                        document.body.classList.add('loading');
                    },
                    success: (file, response) => {
                        document.body.classList.remove('loading');
                        if (response.message == 'success') {
                            handeleAttachment(currentAttachmentTab, response.data);
                        }
                    },
                    error: function (file, response) {
                        document.body.classList.remove('loading');
                    }
                }}
            />
        )
    }


    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const [errorSB, setErrorSB] = useState(false);
    const closeErrorSB = () => setErrorSB(false);
    const [freigbenErrorMessage, setFreigbenErrorMessage] = useState('Bitte geben Sie ein Wunschtermin ein andernfalls ist die DC Freigabe nicht möglich.');
    const [dcFreigbenErrorSB, setDcFreigbenErrorSB] = useState(false);
    const openDcFregebenErrorSB = () => setDcFreigbenErrorSB(true);
    const closDcFregebenErrorsSB = () => setDcFreigbenErrorSB(false);

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgSuccess
        />
    );

    const renderErrorSB = (
        <SoftSnackbar
            color="info"
            icon="info"
            title={t('Create the order first')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgSuccess
        />
    );

    const renderDcFreigebenErrorSB = (
        <SoftSnackbar
            color="error"
            icon=""
            title={t(freigbenErrorMessage)}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={dcFreigbenErrorSB}
            onClose={closDcFregebenErrorsSB}
            close={closDcFregebenErrorsSB}
            bgSuccess
        />
    );

    const onSubmit = (formData) => {
        setSaving(true);

        var loadingElement = document.querySelector('.res-options .btn-primary-save');

        if (loadingElement) {
            loadingElement.classList.add("btn-secondary");
            loadingElement.innerHTML = t('wird geladen...');
        }

        formData.total = 0;
        formData.product_details = JSON.stringify(itemSummery);
        formData.projectid = (props?.projectid ? props?.projectid : params.id);

        callFetch((data?.quations?.order?.id ? "project/order/" + (props?.projectid ? props?.projectid : params.id) : 'project/order'), "POST", formData, setError).then((res) => {

            setTimeout(() => {
                setSaving(false);

                if (loadingElement) {
                    loadingElement.classList.remove("btn-secondary");
                    loadingElement.innerHTML = t('Save');
                }

                if (document.getElementById("main-content")) {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    document.getElementById("main-content").scrollTop = 0;
                }

                if (res?.message == 'success') {
                    openSuccessSB();
                    setRefresh2(refresh2 + 1);
                    setRefresh3(refresh3 + 1);
                }

            }, 2000);
        });
    };

    useEffect(() => {
        setRefresh2(refresh2 + props.refresh);
    }, [props.refresh]);

    return (
        <>
            <div className="row">
                <div className={props?.fullwidth != false ? "col-lg-9 mt-lg-0 inner-main" : 'col-12 inner-main'}>

                    {props?.menu != false ? (
                        <>
                            <SoftBox mt={1} mb={1}>
                                <Grid justifyContent="center">
                                    <Grid item xs={12} lg={8}>
                                        <Stepper activeStep={activeStep} alternativeLabel className="my-3">
                                            {steps.map((label) => {
                                                if (label === '1. Order') {
                                                    return <Step key={label}>
                                                        <StepLabel>1. {t('Auftrag')}</StepLabel>
                                                    </Step>
                                                } else {
                                                    return <Step key={label}>
                                                        <StepLabel>2. {t('Digital Sign')}</StepLabel>
                                                    </Step>
                                                }
                                            }
                                            )}
                                        </Stepper>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </>
                    ) : ''}

                    {props?.overview != false ? (
                        <>
                            <Overview quation_id={data?.quations?.id} title={t("Order")} ></Overview>
                        </>
                    ) : ''}



                    <div className="card mt-3">
                        <div className="card-header pb-0">
                            <h6>{t('New Order')}</h6>
                        </div>
                        <div className="card-body">
                            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">

                                {data?.quations?.order?.id ?
                                    <input type="hidden" defaultValue="PUT" {...register("_method")} />
                                    : ''}


                                <input type="hidden" {...register("status")} />

                                <div className="row g-3">
                                    <div className="col-md-3">
                                        <label>{t('Order Nr.')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('eg: 400001')}
                                            {...register("id", {
                                                required: false,
                                            })}
                                            readOnly
                                        />
                                        <div className="invalid-feedback">{errors.id && errors.id.message}</div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                {t('Angebot')} *
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t('eg: 400001')}
                                                {...register("quation_id", {
                                                    required: true,
                                                })}
                                                readOnly
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.quation_id && errors.quation_id.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                {t('Currency')} *
                                            </label>
                                            <br />
                                            <select
                                                className="form-control"
                                                {...register("currency", {
                                                    required: true,
                                                })}
                                                onChange={(e) => {
                                                    setCurrency(e.target.value);
                                                }}
                                                required
                                            >
                                                <option value="EUR">EUR (€)</option>
                                            </select>
                                            <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <label>
                                            {t('Order Date')} *
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control mb-4 flat-pickr"
                                            placeholder={t('eg. 2001-03-20')}
                                            defaultValue={dateFormat(new Date(), "yyyy-mm-dd")}
                                            {...register("order_date", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.order_date && errors.order_date.message}</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <ul className="order_upload_tabs" id="order_upload_tabs">
                                            <li className={currentAttachmentTab == 'dachansicht' ? 'active' : ''} onClick={() => { fileUploadTab('dachansicht') }} >{t('Hausansicht Front')}</li>
                                            <li className={currentAttachmentTab == 'dachsparren' ? 'active' : ''} onClick={() => { fileUploadTab('dachsparren') }} >{t('Hausansicht Front 2')}</li>
                                            <li className={currentAttachmentTab == 'dachgiebel' ? 'active' : ''} onClick={() => { fileUploadTab('dachgiebel') }} >{t('Hausansicht Taufhöhe')}</li>
                                            <li className={currentAttachmentTab == 'gesamtansicht_von_haus_und_dach' ? 'active' : ''} onClick={() => { fileUploadTab('gesamtansicht_von_haus_und_dach') }} >{t('Hausansicht Rückseite')}</li>
                                            <li className={currentAttachmentTab == 'innendachansicht' ? 'active' : ''} onClick={() => { fileUploadTab('innendachansicht') }} >{t('Innendachansicht')}</li>
                                            <li className={currentAttachmentTab == 'kompletter_zahlerschrank' ? 'active' : ''} onClick={() => { fileUploadTab('kompletter_zahlerschrank') }} >{t('Kompletter Zählerschrank')}</li>
                                            <li className={currentAttachmentTab == 'zahler' ? 'active' : ''} onClick={() => { fileUploadTab('zahler') }} >{t('Zähler')}</li>
                                            <li className={currentAttachmentTab == 'wechselrichter_standort' ? 'active' : ''} onClick={() => { fileUploadTab('wechselrichter_standort') }} >{t('Wechselrichter-Standort')}</li>
                                            <li className={currentAttachmentTab == 'bei_nachust' ? 'active' : ''} onClick={() => { fileUploadTab('bei_nachust') }} >{t('Hausanschlusskasten')}</li>
                                            <li className={currentAttachmentTab == 'ziegel_mit_meterstab' ? 'active' : ''} onClick={() => { fileUploadTab('ziegel_mit_meterstab') }} >{t('Ziegel mit Meterstab')}</li>
                                        </ul>
                                        {errors.dachansicht && errors.dachansicht.message ? (
                                            <div className="invalid-feedback d-block">{t('The dachansicht is required.')}</div>
                                        ) : errors.dachsparren && errors.dachsparren.message ? (
                                            <div className="invalid-feedback d-block">{t('The dachsparren is required.')}</div>
                                        ) : errors.dachgiebel && errors.dachgiebel.message ? (
                                            <div className="invalid-feedback d-block">{t('The dachgiebel is required.')}</div>
                                        ) : errors.gesamtansicht_von_haus_und_dach && errors.gesamtansicht_von_haus_und_dach.message ? (
                                            <div className="invalid-feedback d-block">{t('The Gesamtansicht von Haus und Dach is required.')}</div>
                                        ) : errors.innendachansicht && errors.innendachansicht.message ? (
                                            <div className="invalid-feedback d-block">{t('The innendachansicht is required.')}</div>
                                        ) : errors.kompletter_zahlerschrank && errors.kompletter_zahlerschrank.message ? (
                                            <div className="invalid-feedback d-block">{t('The Kompletter Zählerschrank is required.')}</div>
                                        ) : errors.zahler && errors.zahler.message ? (
                                            <div className="invalid-feedback d-block">{t('The zahler is required.')}</div>
                                        ) : errors.wechselrichter_standort && errors.wechselrichter_standort.message ? (
                                            <div className="invalid-feedback d-block">{t('The Wechselrichter-Standort is required.')}</div>
                                        ) : errors.bei_nachust && errors.bei_nachust.message ? (
                                            <div className="invalid-feedback d-block">{t('The Router (bei Nachust) is required.')}</div>
                                        ) : errors.ziegel_mit_meterstab && errors.ziegel_mit_meterstab.message ? (
                                            <div className="invalid-feedback d-block">{t('The Ziegel mit Meterstab is required.')}</div>
                                        ) : ''}
                                    </div>
                                    <div className="col-md-8">
                                        <SoftBox>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="100%"
                                            >

                                                {dropZoneInitialize()}

                                            </SoftBox>
                                        </SoftBox>

                                        <SoftBox p={3} className="order-processing">
                                            <Grid container spacing={3}>
                                                {eval(currentAttachmentTab) ? eval(currentAttachmentTab).map(function (data, i) {
                                                    return (
                                                        <Grid item key={i} className="text-center">
                                                            <div>
                                                                <a target="_blank" download href={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data.attachment}>
                                                                    <img src={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data.attachment} />
                                                                </a>
                                                            </div>
                                                            <a onClick={() => { removeAttachment(data.id) }}>{t('Remove')}</a>
                                                        </Grid>
                                                    );
                                                }) : <><Grid></Grid></>}
                                            </Grid>
                                        </SoftBox>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-12 mt-0 mb-1">
                                        <div className="form-check">
                                            <label>{t('Gerüst Notwendig')}</label>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e) => {

                                                    if (data.quations.order === null) {
                                                        data.quations.order = {};
                                                    }

                                                    if (e.target.checked === true) {
                                                        setValue('gerust_notwendig', 'JA');
                                                        data.quations.order.gerust_notwendig = 'JA';
                                                    } else {
                                                        setValue('gerust_notwendig', 'NEIN');
                                                        data.quations.order.gerust_notwendig = 'NEIN';
                                                    }
                                                    setData(data);
                                                    setRefresh4(refresh4 + 3)
                                                }}
                                                checked={data?.quations?.order?.gerust_notwendig === 'JA' ? true : false}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-0 mb-1">
                                        <div className="form-check">
                                            <label>{t('Freileitung Isolierung')}</label>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e) => {

                                                    if (data.quations.order === null) {
                                                        data.quations.order = {};
                                                    }

                                                    if (e.target.checked === true) {
                                                        setValue('frealeatung_isolierung', 'JA');
                                                        data.quations.order.frealeatung_isolierung = 'JA';
                                                    } else {
                                                        setValue('frealeatung_isolierung', 'NEIN');
                                                        data.quations.order.frealeatung_isolierung = 'NEIN';
                                                    }
                                                    setData(data);
                                                    setRefresh4(refresh4 + 3)
                                                }}
                                                checked={data?.quations?.order?.frealeatung_isolierung === 'JA' ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="mt-4">
                                    <div className="row">
                                        <div className="col-md-6 offset-md-6 border">
                                            <div className="row">
                                                <div className="col-md-7 border-end">
                                                    <label className="d-block text-end">{t('Netto')}</label>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="input-group">
                                                        <input
                                                            type="number"
                                                            {...register("netto", {
                                                                required: true,
                                                                onChange: (e) => {
                                                                    setValue('brutto', (e.target.value ? e.target.value : 0));
                                                                    setRefresh(refresh + 1);
                                                                }
                                                            })}
                                                            className="form-control"
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text" style={{ borde: 'none' }}>{currency == 'EUR' ? ' € ' : ' $ '}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row border-top">
                                                <div className="col-md-3 border-end">
                                                    <label className="d-block text-end">{t('Tax')}</label>
                                                </div>
                                                <div className="col-md-3 border-end">
                                                    <input type="number"
                                                        {...register("tax", {
                                                            required: true,
                                                        })}
                                                        className="form-control"
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-md-1 border-end">
                                                    <p className="text-end">%</p>
                                                </div>
                                                <div className="col-md-5">
                                                    <p className="text-end">
                                                        <NumericFormat
                                                            value={(getValues('brutto') - getValues('netto'))}
                                                            displayType="text"
                                                            thousandSeparator={"."}
                                                            decimalSeparator=","
                                                            decimalScale={2}
                                                            fixedDecimalScale
                                                            suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row bg-gray-200">
                                                <div className="col-md-7">
                                                    <label className="d-block text-end">{t('Gesamtsumme')}</label>
                                                </div>
                                                <div className="col-md-5">
                                                    <p className="text-end">
                                                        <NumericFormat
                                                            value={getValues('brutto')}
                                                            displayType="text"
                                                            thousandSeparator={"."}
                                                            decimalSeparator=","
                                                            decimalScale={2}
                                                            fixedDecimalScale
                                                            suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {emails && emails.map((email, i) => (
                                    <div key={i} className="row mt-3 d-none">
                                        <div className="col-md-4">
                                            <label>{t('Name')}</label>
                                            <input
                                                type="text"
                                                className="form-control d-inline-block mt-1"
                                                value={email.name} onChange={(e) => {
                                                    emails[i].name = e.target.value;
                                                    setEmails(emails);
                                                    itemSummery.emails = emails;
                                                    setItemSummery(itemSummery);
                                                    setRefresh2(refresh2 + 1);
                                                }}
                                                placeholder="Name"
                                            />
                                        </div>

                                        <div className="col-md-4">
                                            <label>{t('Email')}</label>
                                            <input
                                                type="email"
                                                style={{ width: '93%' }}
                                                className="form-control d-inline-block mt-1"
                                                value={email.email} onChange={(e) => {
                                                    emails[i].email = e.target.value;
                                                    setEmails(emails);
                                                    itemSummery.emails = emails;
                                                    setItemSummery(itemSummery);
                                                    setRefresh2(refresh2 + 1);
                                                }}
                                                placeholder="eg. email@mail.com"
                                            />
                                            &nbsp;
                                            <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete emails[i]; deleteEmail(); }}></i>

                                            <br />
                                        </div>
                                    </div>
                                ))}
                                <p className="btn btn-dark mt-2 d-none" style={{ cursor: 'pointer' }} onClick={() => { setEmails([...emails, { name: '', email: '', currentEmail: '' }]); setRefresh2(refresh2 + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Contact Person')}</p>

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        {!saving && errorData == false && (
                                            <button type="submit" className="btn btn-primary" id="saveOrderBtn">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="button" className="btn btn-secondary" disabled>
                                                {t('wird geladen...')}
                                            </button>
                                        )}


                                        {data?.quations?.order?.id ? (
                                            <>
                                                <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'order/' + data?.quations?.order?.id} className="btn btn-outline-dark ms-2" >{t('Order PDF')}</a>

                                                {data?.dc_product == '1' && data?.ac_product == '1' ? (
                                                    <button
                                                        type="button"
                                                        className={data?.quations?.order?.dc_approve == 1 ? "btn btn-danger ms-2" : "btn btn-outline-danger ms-2"}
                                                        onClick={() => {

                                                            if (data?.desired_assembly) {
                                                                setValue('dc_approve', 1);
                                                                document.getElementById("saveOrderBtn").click();
                                                            } else {
                                                                setFreigbenErrorMessage('Bitte geben Sie ein Wunschtermin ein andernfalls ist die DC Freigabe nicht möglich.');
                                                                openDcFregebenErrorSB();
                                                                console.log('please input data?.desired_assembly')
                                                            }
                                                        }}
                                                    >
                                                        {data?.quations?.order?.dc_approve == 1 ? t('DC Approved') : t('DC Approve')}
                                                    </button>
                                                ) : ''}

                                                {data?.dc_product == '1' && data?.ac_product == '0' ? (
                                                    <button
                                                        type="button"
                                                        className={data?.quations?.order?.dc_beschaffung_approve == 1 ? "btn btn-danger ms-2" : "btn btn-outline-danger ms-2"}
                                                        onClick={() => {
                                                            if (data?.desired_assembly) {
                                                                setValue('dc_beschaffung_approve', 1);
                                                                document.getElementById("saveOrderBtn").click();
                                                            } else {
                                                                setFreigbenErrorMessage('Bitte geben Sie ein Wunschtermin ein andernfalls ist die DC Beschaffung Fregegeben nicht möglich.');
                                                                openDcFregebenErrorSB();
                                                                console.log('please input data?.desired_assembly')
                                                            }
                                                        }}
                                                    >
                                                        {data?.quations?.order?.dc_beschaffung_approve == 1 ? t('DC Beschaffung Fregegeben') : t('DC Beschaffung Freigeben')}
                                                    </button>
                                                ) : ''}

                                                {data?.dc_product == '0' && data?.ac_product == '1' ? (
                                                    <button
                                                        type="button"
                                                        className={data?.quations?.order?.ac_beschaffung_approve == 1 ? "btn btn-success ms-2" : "btn btn-outline-success ms-2"}
                                                        onClick={() => {
                                                            if (data?.desired_assembly) {
                                                                setValue('ac_beschaffung_approve', 1);
                                                                document.getElementById("saveOrderBtn").click();
                                                            } else {
                                                                setFreigbenErrorMessage('Bitte geben Sie ein Wunschtermin ein andernfalls ist die AC Beschaffung Fregegeben nicht möglich.');
                                                                openDcFregebenErrorSB();
                                                                console.log('please input data?.desired_assembly')
                                                            }
                                                        }}
                                                    >
                                                        {data?.quations?.order?.ac_beschaffung_approve == 1 ? t('AC Beschaffung Fregegeben') : t('AC Beschaffung Freigeben')}
                                                    </button>
                                                ) : ''}

                                                <button
                                                    type="button"
                                                    className="btn btn-secondary ms-2"
                                                    onClick={() => {
                                                        document.getElementById("digitalSign").click();
                                                        document.body.scrollTop = 0; // For Safari
                                                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                                        document.getElementById("main-content").scrollTop = 0;
                                                    }}
                                                >{t('Unterschreiben')}</button>
                                            </>
                                        ) : ''}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {props?.sidebar != false ? (
                    <div className="col-lg-3 inner-main">
                        <div className="row">
                            <div className="col-sm-12">
                                {data?.quations?.order?.id ? <button className="btn btn-primary" onClick={() => { document.getElementById('digitalSign').click() }} style={{ width: '100%', backgroundColor: '#CB0C9F', borderColor: '#CB0C9F' }}>{t('Unterschreiben')}</button> : <button className="btn btn-secondary" onClick={() => { setErrorSB(true) }} style={{ width: '100%' }}>{t('Unterschreiben')}</button>}
                            </div>
                            <div className="col-sm-12">
                                {data?.dc_product == '1' && data?.ac_product == '1' ? (
                                    <button className="btn btn-secondary"
                                        onClick={() => {

                                            if (data?.desired_assembly) {
                                                setValue('dc_approve', 1);
                                                document.getElementById("saveOrderBtn").click();
                                            } else {
                                                setFreigbenErrorMessage('Bitte geben Sie ein Wunschtermin ein andernfalls ist die DC Freigabe nicht möglich.');
                                                openDcFregebenErrorSB();
                                                console.log('please input data?.desired_assembly')
                                            }
                                        }}
                                        style={{ width: '100%' }}>{data?.quations?.order?.dc_approve == 1 ? t('DC Approved') : t('DC Approve')}</button>
                                ) : ''}

                                {data?.dc_product == '1' && data?.ac_product == '0' ? (
                                    <button
                                        type="button"
                                        className={data?.quations?.order?.dc_beschaffung_approve == 1 ? "btn btn-danger" : "btn btn-outline-danger"}
                                        onClick={() => {
                                            if (data?.desired_assembly) {
                                                setValue('dc_beschaffung_approve', 1);
                                                document.getElementById("saveOrderBtn").click();
                                            } else {
                                                setFreigbenErrorMessage('Bitte geben Sie ein Wunschtermin ein andernfalls ist die DC Beschaffung Fregegeben nicht möglich.');
                                                openDcFregebenErrorSB();
                                                console.log('please input data?.desired_assembly')
                                            }
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        {data?.quations?.order?.dc_beschaffung_approve == 1 ? t('DC Beschaffung Fregegeben') : t('DC Beschaffung Freigeben')}
                                    </button>
                                ) : ''}

                                {data?.dc_product == '0' && data?.ac_product == '1' ? (
                                    <button
                                        type="button"
                                        className={data?.quations?.order?.ac_beschaffung_approve == 1 ? "btn btn-success" : "btn btn-outline-success"}
                                        onClick={() => {
                                            if (data?.desired_assembly) {
                                                setValue('ac_beschaffung_approve', 1);
                                                document.getElementById("saveOrderBtn").click();
                                            } else {
                                                setFreigbenErrorMessage('Bitte geben Sie ein Wunschtermin ein andernfalls ist die AC Beschaffung Fregegeben nicht möglich.');
                                                openDcFregebenErrorSB();
                                                console.log('please input data?.desired_assembly')
                                            }
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        {data?.quations?.order?.ac_beschaffung_approve == 1 ? t('AC Beschaffung Fregegeben') : t('AC Beschaffung Freigeben')}
                                    </button>
                                ) : ''}
                            </div>
                            <div className="col-12">
                                <div className="card mb-3">
                                    <div className="card-body p-3">
                                        <div className="d-flex">
                                            <div>
                                                <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                    <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="ms-3">
                                                <div className="numbers">
                                                    <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Order Nr.')}</p>
                                                    <h5 className="font-weight-bolder mb-0">
                                                        {data?.quations?.order?.id ?? '---'}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="card mb-3">
                                    <div className="card-body p-3">
                                        <div className="d-flex">
                                            <div>
                                                <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                    <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="ms-3">
                                                <div className="numbers">
                                                    <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Creator')}</p>
                                                    <h5 className="font-weight-bolder mb-0">
                                                        {data?.quations?.order?.user?.name ?? '-'}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DateForm projectid={(props?.projectid ? props?.projectid : params.id)}></DateForm>

                        <div className="card mb-3">
                            <div className="card-header pb-0">
                                <h6>{t('Email')}</h6>
                            </div>
                            <div className="card-body">
                                <EmailForm id={data?.quations?.order?.id} type={'auftrag'} projectid={(props?.projectid ? props?.projectid : params.id)} pdf={true} />
                            </div>
                        </div>
                        <Timeline refresh={refresh2} />
                    </div>
                ) : ''}
                {renderSuccessSB}
                {renderErrorSB}
                {renderDcFreigebenErrorSB}
            </div>
            <ToastContainer />

            <button className="d-none" id="order-refresh-btn" onClick={() => setRefresh3(refresh3 + 1)}></button>
        </>
    );
}

export default Order;

import { AppBar, Badge, Card, Grid, Tab, Tabs } from '@mui/material'
import SoftAvatar from 'components/SoftAvatar'
import React, { useEffect, useState } from 'react'
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import Cube from 'examples/Icons/Cube';
import SummaryCard from './components/SummaryCard';
import tableData from "layouts/pages/users/reports/data/tableData";
import { useParams, Navigate } from 'react-router-dom';
import callFetch from 'helpers/callFetch';
import { styled } from '@mui/material/styles';
import Settings from 'examples/Icons/Settings';
import { useTranslation } from 'react-i18next';
import UserBaseCustomer from './UserBaseCustomer';
import { NumericFormat } from 'react-number-format';
import burceMars from "assets/images/bruce-mars.jpg";
import curved0 from "assets/images/curved-images/curved0.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import TaskCard from './components/TaskCard';
import PlaceholderCard from 'examples/Cards/PlaceholderCard';
import NotesModal from './components/NotesModal';
import Cookies from 'js-cookie';

import TicketCreate from "../ticket/Create";

const CustomerDetails = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(1);
  const [editDelay, setEditDelay] = useState(0);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [tabsOrientation2, setTabsOrientation2] = useState("horizontal");
  const [tabValue2, setTabValue2] = useState(0);
  const [customer, setCustomer] = useState({})
  const [customerOverview, setCustomerOverview] = useState({})
  const [ticketsReklamation, setRicketsReklamation] = useState({})
  const [createProject, setCreateProject] = useState(false)
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const handleSetTabValue2 = (event, newValue) => setTabValue2(newValue);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (

          <div className='mt-4'>{children}</div>

        )}
      </div>
    );
  }

  useEffect(() => { setEditDelay(editDelay + 1) }, [0])
  useEffect(() => {

    if (editDelay == 1)
      callFetch("customers/" + params.id, "GET", []).then((res) => {
        setCustomer(res.data);
        setCustomerOverview(res.data.overview);
      });
  }, [editDelay, params.id]);

  useEffect(() => {
    if (!customer?.customer?.project_id) return;

    callFetch("customer/tickets-reklamation/" + customer?.customer?.project_id, "GET", []).then((res) => {
      setRicketsReklamation(res.data);
    });

  }, [customer?.customer?.project_id]);

  const SmallAvatar = styled(SoftAvatar)(({ theme }) => ({
    width: 22,
    height: 22,
    backgroundColor: "#fff",
    color: "darkgray",
    cursor: "pointer"
  }));
  return createProject ? <Navigate to={'/projects/create/'+params.id} /> : (
    <>
      <SoftBox position="relative">
        <SoftBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="12.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${curved0})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            backdropFilter: `saturate(200%) blur(30px)`,
            backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
            boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <SoftAvatar
                src={'/assets/img/placeholder.png'}
                alt="profile-image"
                variant="rounded"
                size="xl"
                shadow="sm"
              />
            </Grid>
            <Grid item>
              <SoftBox height="100%" mt={0.5} lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  {customer?.customer ? customer?.customer?.name + " " + customer?.customer?.vorname : ''}
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="medium">
                  {customer?.customer?.customer_number}
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation2}
                  value={tabValue2}
                  onChange={handleSetTabValue2}
                  sx={{ background: "transparent" }}
                >

                  <Tab onClick={() => {
                    setCreateProject(true)
                  }} style={{ fontSize: "11px", fontWeight: "bold" }} label="Create Project" />

                  {/*JSON.parse(Cookies.get('permissions')).indexOf("RecC") !== -1 ? (
                    <Tab style={{ fontSize: "11px", fontWeight: "bold" }} label={t('Reklamation anlegen')} data-bs-toggle="modal" data-bs-target="#createReclamationModalOpen" />
                  ) : <></>*/}

                  {JSON.parse(Cookies.get('permissions')).indexOf("TicC") !== -1 ? (
                    <Tab style={{ fontSize: "11px", fontWeight: "bold" }} label={t('Ticket anlegen')} data-bs-toggle="modal" data-bs-target="#createTicketModalOpen" />
                  ) : <></>}

                  <Tab style={{ fontSize: "11px", fontWeight: "bold" }} className="" data-bs-toggle="modal" data-bs-target="#AddnotesModal" label="Notiz hinzufügen" />


                  {/*
                  <Tab style={{ fontSize: "11px", fontWeight: "bold" }} label="Message" />
                  <Tab style={{ fontSize: "11px", fontWeight: "bold" }} label="Settings" icon={<Settings />} />*/}
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
        </Card>
      </SoftBox>

      <div className="col-md-2 mt-4" >
        <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{ background: "transparent" }}

            >
              <Tab style={{ fontSize: "11px", fontWeight: "bold" }} label={t("OVERVIEW")} /*icon={<Cube />}*/ />
              <Tab style={{ fontSize: "11px", fontWeight: "bold" }} label={t("PROJECTS")} /*icon={<Settings />}*/ />
            </Tabs>
          </AppBar>
        </Grid>
      </div>
      <TabPanel value={tabValue} index={0}>
        <div className='row' >
          <div className='col-md-8 col-lg-8' >
            <div className="card w-100" >
              <div className="card-body pb-4">
                <h6 className=' fw-normal' >Kunde</h6>
                <div className='d-flex align-content-center' >
                  <SoftTypography className="w-15" variant="button" fontWeight="medium">{t('Name')}:</SoftTypography>
                  <SoftTypography variant="small" color="text" fontSize="0.9rem">{customer?.customer ? customer?.customer?.name + " " + customer?.customer?.vorname : ''}</SoftTypography>
                </div>
                <div className='d-flex align-content-center' >
                  <SoftTypography className="w-15" variant="button" fontWeight="medium">{t('Phone')}:</SoftTypography>
                  <SoftTypography variant="small" color="text" fontSize="0.9rem">{customer?.customer?.telefonnummer}</SoftTypography>
                </div>
                <div className='d-flex align-content-center' >
                  <SoftTypography className="w-15" variant="button" fontWeight="medium">{t('E-Mail')}:</SoftTypography>
                  <SoftTypography variant="small" color="text" fontSize="0.9rem">{customer?.customer?.email}</SoftTypography>
                </div>
                <div className='d-flex align-content-center'>
                  <SoftTypography className="w-15" variant="button" fontWeight="medium">{t('Address')}:</SoftTypography>
                  <SoftTypography variant="small" color="text" fontSize="0.9rem">{customer?.customer ? customer?.customer?.plz + ", " + customer?.customer?.ort + ", " + customer?.customer?.street + ", " + customer?.customer?.nr : ''}</SoftTypography>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 mt-3 mt-sm-0 mt-md-0 mt-lg-0" >
            <div className="row" >
              <div className="col-md-6 mt-xs-3 mt-sm-3 mt-md-0 mt-lg-0" >
                <SummaryCard
                  title={t("Orders")}
                  icon="wallet"
                  count={customer?.order}
                />
              </div>
              <div className="col-md-6 mt-xs-3 mt-3 mt-sm-3 mt-md-0 mt-lg-0" >
                <SummaryCard
                  title={t("Quotations")}
                  icon="description"
                  count={customer?.quation}
                />
              </div>
              <div className="col-md-6 mt-3" >
                <SummaryCard
                  title={t("Projects")}
                  icon="language_icon"
                  count={customer?.project}
                />
              </div>
              <div className="col-md-6 mt-3" >
                <SummaryCard
                  title={t("Total Sales")}
                  icon="shopping_cart"
                  count={<NumericFormat
                    value={Number(customer?.total_sale)}
                    displayType="text"
                    thousandSeparator={"."}
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    suffix={' € '}

                  />}
                />

              </div>
            </div>
          </div>
        </div>
      </TabPanel>

      <TabPanel value={tabValue} index={1} >
        <div className="row">
          <div className="col-12">
            <div className="card mb-4">
              <div className="card-header pb-0">

              </div>
              <div className="card-body px-0 pt-0 pb-2">
                <UserBaseCustomer />
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <div className="mt-5">
        <div className="card">
          <div className="card-body">
            <SoftTypography variant="h5" fontSize="18px" >{t('Interaktionen')}</SoftTypography>
            <SoftTypography variant="caption" color="inherit" fontSize="13px" >{t('Kundenbezogene Reklamationen & Tickets')}</SoftTypography>
            <div className="row mt-4">
              {ticketsReklamation?.data && ticketsReklamation?.data.map((ticketsReklamation) => (
                <div className="col-12 col-md-6 col-lg-6 col-xl-3 mb-3">
                  <TaskCard
                    ticket={ticketsReklamation?.interaktionen_type + '-' + ticketsReklamation?.id}
                    id={ticketsReklamation?.id}
                    project_id={ticketsReklamation?.project_id}
                    title={ticketsReklamation?.title}
                    interaktionen_type={ticketsReklamation?.interaktionen_type}
                    description={ticketsReklamation?.description}
                    members={[team2, team3, team1, team2, team3]}
                  />
                </div>
              ))}
              {/*
              <div className="col-12 col-md-6 col-lg-6 col-xl-3 mt-3 mt-md-3 mt-lg-3 mt-xl-0">
                <PlaceholderCard outlined="none" title={{ variant: "h6", text: "Neuer Task" }} hasBorder />
              </div>
              */}
            </div>
          </div>
        </div>
      </div>
      <NotesModal />

      {JSON.parse(Cookies.get('permissions')).indexOf("TicC") !== -1 ? (
        <TicketCreate setrefresh={setRefresh} customerId={params?.id} />
      ) : ''}
    </>
  )
}

export default CustomerDetails

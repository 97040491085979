import { React, useEffect, useState, useRef } from "react"; 
import { Navigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import { GoogleMap, Marker, InfoWindow, useLoadScript, Autocomplete } from '@react-google-maps/api'; 

function Map(props) {
    let params = useParams();  
    console.log(props);
    const [mapDisplay, setMapDisplay] = useState(false);
    const [mapZoom, setMapZoom] = useState(21); 
    const [mapCenter, setMapCenter] = useState({ lat: props?.lat, lng: props?.lng });
    //const libraries = ["places"]

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ["places"]
    });

    function handleZoomChanged() {
        setMapZoom(this.getZoom());
    }

    useEffect(() => {
        setMapCenter({ lat: parseFloat(props?.lat), lng: parseFloat(props?.lng) });
    }, []);
 

    if (loadError) return "Error loading maps"
    if (!isLoaded) return;// "Loading Maps"


    return <GoogleMap
        id='project-location'
        mapContainerStyle={{ height: '100%', width: '100%', borderRadius: '7px', position: 'relative' }}
        mapContainerClassName={'googleMapContainer'}
        zoom={mapZoom}
        center={mapCenter}
        onZoomChanged={handleZoomChanged}
        onMouse
        options={{
            disableDefaultUI: true, 
            mapTypeId: 'satellite',  
            zoomControl:true,
        }} 
    ></GoogleMap>;
}

export default Map;

import { React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import dateFormat, { masks } from "dateformat";
import { NumericFormat } from 'react-number-format';
import callFetch from "../../../helpers/callFetch";
import CorrectionRequestView from "./CorrectionRequestView";

import SoftTypography from "components/SoftTypography";
import { NavLink } from "react-router-dom";

function TimelineNew(props) {
    const { t } = useTranslation();
    let params = useParams();

    const [refresh, setRefresh] = useState(1);
    const [data, setData] = useState([]);
    var AppointmentSerial = 1;
    var QuotationSerial = 1;
    var Orderserial = 1;
    var serial = 1;


    useEffect(() => {
        if (!params.id) return;

        callFetch("project/timelines-overview/" + (props?.projectid ? props?.projectid : params.id), "GET", []).then((res) => {
            setData(res.data);
            console.log(res.data);
        });
    }, [props?.projectid + refresh + params?.id + (props?.refresh ?? 0)]);

    return (
        <>
            {data && data.project ? (
                <div className="card" style={{boxShadow: 'none'}}>
                     <SoftTypography variant="h6" fontWeight="bold">{t('Project Status')}</SoftTypography>
                    <div className="card-body mt-3 callcenter-area" style={{height: '305px', backgroundColor: 'rgb(248, 249, 250)', borderRadius: '15px', overflowY: 'scroll'}}> 
                        <div className="timeline timeline-one-side" data-timeline-axis-style="dotted">
                            
                            {data.quations && data.quations.map((quation) => (
                                <>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <i className="ni ni-bell-55 text-success text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Quotation')}</h6>
                                            <p className="text-secondary font-weight-bold text-xs mt-1">{dateFormat(quation.created_at, "dd.mm.yyyy")}</p>

                                            <p className="text-sm m-0">
                                                <SoftTypography variant="caption"><strong>{t('Quotation Nr.')}: </strong></SoftTypography> <SoftTypography variant="caption">{quation.id}</SoftTypography>
                                            </p>

                                             
                                                <p className="text-sm m-0 mb-1">
                                                    <SoftTypography variant="caption"><strong>{t('Price')} Netto: </strong></SoftTypography>
                                                    <SoftTypography variant="caption">
                                                        <NumericFormat value={quation.gesami_netto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} />€
                                                    </SoftTypography>
                                                </p>
                                             

                                            {data.project.corrections && data.project.corrections.map((correction) => (
                                                <>
                                                    {correction.related == 'Quotation' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={QuotationSerial++}></CorrectionRequestView> : ''}
                                                </>
                                            ))}

                                            {quation.email_trans && quation.email_trans.map((email_tran) => (
                                                <>

                                                    {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                                        <>
                                                            <p className="text-sm m-0 mb-1">
                                                                <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                                                <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                                            </p>
                                                            <p className="text-sm m-0 mb-1">
                                                                <SoftTypography variant="caption">{email}</SoftTypography>
                                                            </p>
                                                        </>
                                                    ))}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            ))}

                            {data.orders && data.orders.map((order) => (
                                <>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <i className="ni ni-bell-55 text-success text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Order')}</h6>
                                            <p className="text-secondary font-weight-bold text-xs mt-1">{dateFormat(order.created_at, "dd.mm.yyyy")}</p>


                                            <p className="text-sm m-0">
                                                <SoftTypography variant="caption"><strong>{t('Order Nr.')}: </strong></SoftTypography> <SoftTypography variant="caption">{order.id}</SoftTypography>
                                            </p>
                                           
                                                <p className="text-sm m-0 mb-1">
                                                    <SoftTypography variant="caption"><strong>{t('Price')} Netto: </strong></SoftTypography> <SoftTypography variant="caption"><NumericFormat value={order.netto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} />€</SoftTypography>
                                                </p>
                                              

                                            {data.project.corrections && data.project.corrections.map((correction) => (
                                                <>
                                                    {correction.related == 'Order' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={serial++}></CorrectionRequestView> : ''}
                                                </>
                                            ))}

                                            <p className="text-sm m-0">
                                                <SoftTypography variant="caption"><strong>{t('Wunschtermin vorhanden')}: </strong></SoftTypography> <SoftTypography variant="caption">{/*dateFormat(data?.project?.desired_assembly, "dd.mm.yyyy")*/ data?.project?.desired_assembly}</SoftTypography>
                                            </p>

                                            {order.email_trans && order.email_trans.map((email_tran) => (
                                                <>

                                                    {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                                        <>
                                                            <p className="text-sm m-0 mb-1">
                                                                <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                                                <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                                            </p>
                                                            <p className="text-sm m-0 mb-1">
                                                                <SoftTypography variant="caption">{email}</SoftTypography>
                                                            </p>
                                                        </>
                                                    ))}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            ))}

                            {data.assign_orders && data.assign_orders.map((assign_order) => (
                                <>
                                    {assign_order.dachmontag_elektromontage == 'Dachmontage' ? (
                                        <>
                                            <div className="timeline-block mb-3">
                                                <span className="timeline-step">
                                                    <i className="ni ni-bell-55 text-success text-gradient" />
                                                </span>
                                                <div className="timeline-content">
                                                    <h6 className="text-dark text-sm font-weight-bold mb-0">{assign_order.dachmontag_elektromontage}</h6>
                                                    <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order.created_at, "dd.mm.yyyy")}</p>
                                                    <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order.created_at, "HH::ss")}</p>

                                                    <p className="text-sm m-0">
                                                        <SoftTypography variant="caption"><strong>{t('Techniker')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order.employee.name}</SoftTypography>
                                                    </p>

                                                    <p className="text-sm mb-1">
                                                        <SoftTypography variant="caption"><strong>{t('Termin')}: </strong></SoftTypography> <SoftTypography variant="caption">{dateFormat(assign_order?.date, "dd.mm.yyyy")}</SoftTypography>
                                                    </p>

                                                    <p className="text-sm m-0">
                                                        <SoftTypography variant="caption"><strong>{t('Stadium')}: </strong></SoftTypography>
                                                        <SoftTypography
                                                            sx={{
                                                                fontWeight: '500'
                                                            }}
                                                            variant="caption"
                                                            color={assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? 'success' : ''}>{assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? t('Successfull') : t("Not Successfull")}</SoftTypography>
                                                        {props?.dcStatusEdit ? (
                                                            <i
                                                                className="fa fa-pencil ms-2"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => {
                                                                    document.getElementById('ComisioningModalOpen').click();
                                                                }}
                                                            ></i>
                                                        ) : ''}

                                                    </p>
                                                    <p className="text-sm mb-0">
                                                        <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? <a target={'_blank'} href={process.env.REACT_APP_BACKEND_URL + 'dcabname/' + assign_order?.dcabnameprotokoll?.id}>DC Abnahmeprotokoll PDF</a> : "--"}</SoftTypography>
                                                    </p>

                                                    <p className="text-sm mb-1">
                                                        <SoftTypography variant="caption"><strong>{t('Eingangsrechnung')}: </strong></SoftTypography>
                                                        <SoftTypography variant="caption">
                                                            {data.project.incoming_invoces && data.project.incoming_invoces.map((incoming_invoce) => (
                                                                <>
                                                                    {incoming_invoce?.abteilung == 'Dachmontage' ? (
                                                                        <NavLink className={'ms-1'} to={'/eingangsrechnungen/' + incoming_invoce?.eingangs_rechungs_nummer}>{'ER-' + incoming_invoce?.eingangs_rechungs_nummer}</NavLink>
                                                                    ) : ''}
                                                                </>
                                                            ))}
                                                        </SoftTypography>
                                                    </p>

                                                    {data.project.corrections && data.project.corrections.map((correction) => (
                                                        <>
                                                            {correction.related == 'auftrag' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={Orderserial++}></CorrectionRequestView> : ''}
                                                        </>
                                                    ))}

                                                    {assign_order.email_trans && assign_order.email_trans.map((email_tran) => (
                                                        <>
                                                            {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                                                <>
                                                                    <p className="text-sm m-0 mb-1">
                                                                        <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                                                        <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                                                    </p>
                                                                    <p className="text-sm m-0 mb-1">
                                                                        <SoftTypography variant="caption">{email}</SoftTypography>
                                                                    </p>
                                                                </>
                                                            ))}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                                <>
                                                    {reclamation?.department == 'Dachmontage' ? (
                                                        <div className="timeline-block mb-3">
                                                            <span className="timeline-step">
                                                                <i className="ni ni-bell-55 text-success text-gradient" />
                                                            </span>
                                                            <div className="timeline-content">
                                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{'Reklamation '}</h6>
                                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "dd.mm.yyyy")}</p>
                                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "HH:ss")}</p>

                                                                <p className="text-sm m-0">
                                                                    <SoftTypography variant="caption"><strong>{t('Rekla.Nr')}: </strong></SoftTypography>
                                                                    <SoftTypography variant="caption"><NavLink to={'/reclamation/' + reclamation.id}>{'R-' + reclamation?.id}</NavLink></SoftTypography>
                                                                </p>

                                                                <p className="text-sm m-0 mb-0">
                                                                    <SoftTypography variant="caption"><strong>{t('Abteilung')}: </strong></SoftTypography>
                                                                    <SoftTypography variant="caption">{reclamation?.department}</SoftTypography>
                                                                </p>

                                                                <p className="text-sm m-0 mb-0">
                                                                    <SoftTypography variant="caption"><strong>{t('Prioritat')}: </strong></SoftTypography>
                                                                    <SoftTypography variant="caption">{reclamation?.priority}</SoftTypography>
                                                                </p>

                                                                <p className="text-sm m-0 mb-0">
                                                                    <SoftTypography variant="caption"><strong>{t('Erstellt von')}: </strong></SoftTypography>
                                                                    <SoftTypography variant="caption">{reclamation?.user?.name}</SoftTypography>
                                                                </p>
                                                                <p className="text-sm m-0 mb-0">
                                                                    <SoftTypography variant="caption"><strong>{t('Leitstand')}: </strong></SoftTypography>
                                                                    <SoftTypography variant="caption">{reclamation?.dc_freigeben == 1 ? reclamation?.dachmontag?.employee?.name + ',' + t('Termin') + ':' + dateFormat(reclamation.dachmontag.date, "dd.mm.yyyy") : '--'}</SoftTypography>
                                                                </p>

                                                                <p className="text-sm mb-0">
                                                                    <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography> <SoftTypography variant="caption">{reclamation?.status == 1? t('Erfolgreich') : t('Nicht Erfolgreich')}</SoftTypography>
                                                                </p> 

                                                                {reclamation?.products && JSON.parse(reclamation?.products).map(function (product, i) {
                                                                    return (
                                                                        <p className="text-sm m-0 mb-0">
                                                                            <SoftTypography variant="caption"><strong>{t('Product')}: </strong></SoftTypography>
                                                                            <SoftTypography variant="caption">{product?.quantity} Stück. {product?.title}</SoftTypography>
                                                                        </p>

                                                                    );
                                                                })} 
                                                               


                                                                {reclamation.reclamation_plan && reclamation.reclamation_plan.map((reclamationplan) => (
                                                                    <>
                                                                        {reclamationplan.email_trans && reclamationplan.email_trans.map((email_tran) => (
                                                                            <>

                                                                                {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                                                                    <>
                                                                                        {email ? (
                                                                                            <>
                                                                                                <p className="text-sm m-0 mb-1">
                                                                                                    <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                                                                                    <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                                                                                </p>
                                                                                                <p className="text-sm m-0 mb-1">
                                                                                                    <SoftTypography variant="caption">{email}</SoftTypography>
                                                                                                </p>
                                                                                            </>
                                                                                        ) : ''}

                                                                                    </>
                                                                                ))}
                                                                            </>
                                                                        ))}
                                                                    </>
                                                                ))}


                                                            </div>
                                                        </div>
                                                    ) : ''}
                                                </>
                                            ))}

                                            <div className="timeline-block mb-3">
                                                <span className="timeline-step">
                                                    <i className="ni ni-bell-55 text-success text-gradient" />
                                                </span>
                                                <div className="timeline-content">
                                                    <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Beschaffung')}</h6>
                                                    <p className="text-sm mt-1 mb-0">
                                                        <SoftTypography variant="caption"><strong>{t('Liefertermin Modul')}: </strong></SoftTypography>
                                                        <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.liefertermin_module ? dateFormat(data?.project?.purchase?.liefertermin_module, "dd.mm.yyyy") : '---'}</SoftTypography><br />
                                                        <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.liefertermin_module ? data?.project?.quations?.anzahl_module + ' Stck. | ' + (data?.project?.quations?.module_eingeben == 'no' ? data?.project?.quations?.module?.name : data?.project?.quations?.module_name) : '---'}</SoftTypography>
                                                    </p>
                                                    <p className="text-sm mt-1 mb-0">
                                                        <SoftTypography variant="caption"><strong>{t('Liefertermin Speicher')}: </strong></SoftTypography>
                                                        <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.siefertermin_speicher ? dateFormat(data?.project?.purchase?.siefertermin_speicher, "dd.mm.yyyy") : '---'}</SoftTypography><br />
                                                        <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.siefertermin_speicher ? (data?.project?.quations?.speicher_eingeben == 'no' ? data?.project?.quations?.speichers?.name : data?.project?.quations?.speicher_titel) : '---'}</SoftTypography>
                                                    </p>
                                                    <p className="text-sm mt-1 mb-0">
                                                        <SoftTypography variant="caption"><strong>{t('Liefertermin Großhändler')}: </strong></SoftTypography> <SoftTypography variant="caption">{data?.project?.purchase && data?.project?.purchase?.liefertermin_grobhandler ? dateFormat(data?.project?.purchase?.liefertermin_grobhandler, "dd.mm.yyyy") : '---'}</SoftTypography>
                                                    </p>
                                                </div>
                                            </div>

                                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                                <>
                                                    {reclamation?.department == 'Einkauf' ? (
                                                        <div className="timeline-block mb-3">
                                                            <span className="timeline-step">
                                                                <i className="ni ni-bell-55 text-success text-gradient" />
                                                            </span>
                                                            <div className="timeline-content">
                                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{'Reklamation '}</h6>
                                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "dd.mm.yyyy")}</p>
                                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "HH:ss")}</p>

                                                                <p className="text-sm m-0">
                                                                    <SoftTypography variant="caption"><strong>{t('Rekla.Nr')}: </strong></SoftTypography>
                                                                    <SoftTypography variant="caption"><NavLink to={'/reclamation/' + reclamation.id}>{'R-' + reclamation?.id}</NavLink></SoftTypography>
                                                                </p>

                                                                <p className="text-sm m-0 mb-0">
                                                                    <SoftTypography variant="caption"><strong>{t('Abteilung')}: </strong></SoftTypography>
                                                                    <SoftTypography variant="caption">{reclamation?.department}</SoftTypography>
                                                                </p>

                                                                <p className="text-sm m-0 mb-0">
                                                                    <SoftTypography variant="caption"><strong>{t('Prioritat')}: </strong></SoftTypography>
                                                                    <SoftTypography variant="caption">{reclamation?.priority}</SoftTypography>
                                                                </p>

                                                                <p className="text-sm m-0 mb-0">
                                                                    <SoftTypography variant="caption"><strong>{t('Erstellt von')}: </strong></SoftTypography>
                                                                    <SoftTypography variant="caption">{reclamation?.user?.name}</SoftTypography>
                                                                </p>
                                                                <p className="text-sm m-0 mb-0">
                                                                    <SoftTypography variant="caption"><strong>{t('Leitstand')}: </strong></SoftTypography>
                                                                    <SoftTypography variant="caption">{reclamation?.dc_freigeben == 1 ? t('DC Approved') : '--'}</SoftTypography>
                                                                </p>

                                                                <p className="text-sm mb-0">
                                                                    <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography> <SoftTypography variant="caption">{reclamation?.status == 1? t('Erfolgreich') : t('Nicht Erfolgreich')}</SoftTypography>
                                                                </p> 

                                                                {reclamation?.products && JSON.parse(reclamation?.products).map(function (product, i) {
                                                                    return (
                                                                        <p className="text-sm m-0 mb-0">
                                                                            <SoftTypography variant="caption"><strong>{t('Product')}: </strong></SoftTypography>
                                                                            <SoftTypography variant="caption">{product?.quantity} Stück. {product?.title}</SoftTypography>
                                                                        </p>

                                                                    );
                                                                })}

                                                                {reclamation.reclamation_plan && reclamation.reclamation_plan.map((reclamationplan) => (
                                                                    <>
                                                                        {reclamationplan.email_trans && reclamationplan.email_trans.map((email_tran) => (
                                                                            <>

                                                                                {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                                                                    <>
                                                                                        {email ? (
                                                                                            <>
                                                                                                <p className="text-sm m-0 mb-1">
                                                                                                    <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                                                                                    <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                                                                                </p>
                                                                                                <p className="text-sm m-0 mb-1">
                                                                                                    <SoftTypography variant="caption">{email}</SoftTypography>
                                                                                                </p>
                                                                                            </>
                                                                                        ) : ''}

                                                                                    </>
                                                                                ))}
                                                                            </>
                                                                        ))}
                                                                    </>
                                                                ))}

                                                            </div>
                                                        </div>
                                                    ) : ''}
                                                </>
                                            ))}
                                        </>
                                    ) : ''
                                    }
                                </>
                            ))}


                            {data.invoices && data.invoices.map((invoice, invoice_serial) => (
                                <>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <i className="ni ni-bell-55 text-success text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Invoice') + ' ' + JSON.parse(invoice?.product_details)?.amountPerchantage + '%'}</h6>
                                            <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(invoice?.created_at, "dd.mm.yyyy")}</p>

                                            <p className="text-sm mt-1 mb-0">
                                                <SoftTypography variant="caption"><strong>{t('InvoiceNr.')}: </strong></SoftTypography> <SoftTypography variant="caption">{invoice?.id}</SoftTypography>
                                            </p>
                                            <p className="text-sm mt-0 mb-1"><SoftTypography variant="caption"><strong>{t('Payment')}: </strong></SoftTypography> {
                                                invoice?.payment_items[0]?.paid_ammount >= JSON.parse(invoice?.product_details)?.amount ? (
                                                    <>
                                                        <SoftTypography variant="caption" style={{ color: ((invoice_serial + 1) + '/2 Getilgt') == '1/2 Getilgt' ? '#d2a400' : 'success' }} fontWeight="medium" color={((invoice_serial + 1) + '/2 Getilgt') == '1/2 Getilgt' ? 'warning' : 'success'} sx={{ lineHeight: 0 }}>
                                                            {t((invoice_serial + 1) + '/2 Getilgt')}
                                                        </SoftTypography>
                                                    </>
                                                )
                                                    : (
                                                        <>
                                                            <SoftTypography variant="caption" fontWeight="medium" color="error" sx={{ lineHeight: 0 }}>
                                                                {t('Open')}
                                                            </SoftTypography>
                                                        </>
                                                    )
                                            }</p>
                                        </div>
                                    </div>
                                </>
                            ))}

                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                <>
                                    {reclamation?.department == 'Buchhaltung' ? (
                                        <div className="timeline-block mb-3">
                                            <span className="timeline-step">
                                                <i className="ni ni-bell-55 text-success text-gradient" />
                                            </span>
                                            <div className="timeline-content">
                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{'Reklamation '}</h6>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "dd.mm.yyyy")}</p>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "HH:ss")}</p>

                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Rekla.Nr')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption"><NavLink to={'/reclamation/' + reclamation.id}>{'R-' + reclamation?.id}</NavLink></SoftTypography>
                                                </p>

                                                <p className="text-sm m-0 mb-0">
                                                    <SoftTypography variant="caption"><strong>{t('Abteilung')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption">{reclamation?.department}</SoftTypography>
                                                </p>

                                                <p className="text-sm m-0 mb-0">
                                                    <SoftTypography variant="caption"><strong>{t('Prioritat')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption">{reclamation?.priority}</SoftTypography>
                                                </p>

                                                <p className="text-sm m-0 mb-0">
                                                    <SoftTypography variant="caption"><strong>{t('Erstellt von')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption">{reclamation?.user?.name}</SoftTypography>
                                                </p>
                                                <p className="text-sm m-0 mb-0">
                                                    <SoftTypography variant="caption"><strong>{t('Leitstand')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption">{reclamation?.dc_freigeben == 1 ? t('DC Approved') : '--'}</SoftTypography>
                                                </p>

                                                <p className="text-sm mb-0">
                                                     <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography> <SoftTypography variant="caption">{reclamation?.status == 1? t('Erfolgreich') : t('Nicht Erfolgreich')}</SoftTypography>
                                                </p> 

                                                {reclamation?.products && JSON.parse(reclamation?.products).map(function (product, i) {
                                                    return (
                                                        <p className="text-sm m-0 mb-0">
                                                            <SoftTypography variant="caption"><strong>{t('Product')}: </strong></SoftTypography>
                                                            <SoftTypography variant="caption">{product?.quantity} Stück. {product?.title}</SoftTypography>
                                                        </p>

                                                    );
                                                })}

                                                {reclamation.reclamation_plan && reclamation.reclamation_plan.map((reclamationplan) => (
                                                    <>
                                                        {reclamationplan.email_trans && reclamationplan.email_trans.map((email_tran) => (
                                                            <>

                                                                {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                                                    <>
                                                                        {email ? (
                                                                            <>
                                                                                <p className="text-sm m-0 mb-1">
                                                                                    <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                                                                    <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                                                                </p>
                                                                                <p className="text-sm m-0 mb-1">
                                                                                    <SoftTypography variant="caption">{email}</SoftTypography>
                                                                                </p>
                                                                            </>
                                                                        ) : ''}

                                                                    </>
                                                                ))}
                                                            </>
                                                        ))}
                                                    </>
                                                ))}

                                            </div>
                                        </div>
                                    ) : ''}
                                </>
                            ))}

                            {data.assign_orders && data.assign_orders.map((assign_order) => (
                                <>
                                    {assign_order.dachmontag_elektromontage == 'Elektromontage' ? (
                                        <div className="timeline-block mb-3">
                                            <span className="timeline-step">
                                                <i className="ni ni-bell-55 text-success text-gradient" />
                                            </span>
                                            <div className="timeline-content">
                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{assign_order.dachmontag_elektromontage}</h6>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order.created_at, "dd.mm.yyyy")}</p>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{assign_order.time}</p>

                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Employee')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order?.employee?.name}</SoftTypography>
                                                </p>

                                                {/*
                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Stadium')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption" color={assign_order?.acabnameprotokoll?.comisioning_status == "1" ? 'success' : ''}>{assign_order?.acabnameprotokoll?.comisioning_status == "1" ? 'Successfull' : "Not Successfull"}</SoftTypography>
                                                </p>
                                                */}

                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Stadium')}: </strong></SoftTypography>
                                                    <SoftTypography
                                                        sx={{
                                                            fontWeight: '500'
                                                        }}
                                                        variant="caption"
                                                        color={assign_order?.acabnameprotokoll?.comisioning_status == "1" ? 'success' : ''}>{assign_order?.acabnameprotokoll?.comisioning_status == "1" ? t('Successfull') : t("Not Successfull")}</SoftTypography>
                                                    {props?.acStatusEdit ? (
                                                        <i
                                                            className="fa fa-pencil ms-2"
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => {
                                                                document.getElementById('ComisioningModalOpen').click();
                                                            }}
                                                        ></i>
                                                    ) : ''}

                                                </p>
                                                <p className="text-sm mb-0">
                                                    <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order?.acabnameprotokoll?.comisioning_status == "1" ? <a target={'_blank'} href={process.env.REACT_APP_BACKEND_URL + 'acabname/' + assign_order?.acabnameprotokoll?.id}>AC Abnahmeprotokoll PDF</a> : "--"}</SoftTypography>
                                                </p>

                                                <p className="text-sm mb-1">
                                                    <SoftTypography variant="caption"><strong>{t('Eingangsrechnung')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption">
                                                        {data.project.incoming_invoces && data.project.incoming_invoces.map((incoming_invoce) => (
                                                            <>
                                                                {incoming_invoce?.abteilung == 'Elektromontage' ? (
                                                                    <NavLink className={'ms-1'} to={'/eingangsrechnungen/' + incoming_invoce?.eingangs_rechungs_nummer}>{'ER-' + incoming_invoce?.eingangs_rechungs_nummer}</NavLink>
                                                                ) : ''}
                                                            </>
                                                        ))}
                                                    </SoftTypography>
                                                </p>

                                                {data.project.corrections && data.project.corrections.map((correction) => (
                                                    <>
                                                        {correction.related == 'auftrag' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={Orderserial++}></CorrectionRequestView> : ''}
                                                    </>
                                                ))}

                                                {assign_order.email_trans && assign_order.email_trans.map((email_tran) => (
                                                    <>
                                                        {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                                            <>
                                                                <p className="text-sm m-0 mb-1">
                                                                    <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                                                    <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                                                </p>
                                                                <p className="text-sm m-0 mb-1">
                                                                    <SoftTypography variant="caption">{email}</SoftTypography>
                                                                </p>
                                                            </>
                                                        ))}
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    ) : ''
                                    }
                                </>
                            ))}

                            {data.project.reclamations && data.project.reclamations.map((reclamation, index) => (
                                <>
                                    {reclamation?.department == 'Elektromontage' ? (
                                        <div className="timeline-block mb-3">
                                            <span className="timeline-step">
                                                <i className="ni ni-bell-55 text-success text-gradient" />
                                            </span>
                                            <div className="timeline-content">
                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{'Reklamation '}</h6>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "dd.mm.yyyy")}</p>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(reclamation.created_at, "HH:ss")}</p>

                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Rekla.Nr')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption"><NavLink to={'/reclamation/' + reclamation.id}>{'R-' + reclamation?.id}</NavLink></SoftTypography>
                                                </p>

                                                <p className="text-sm m-0 mb-0">
                                                    <SoftTypography variant="caption"><strong>{t('Abteilung')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption">{reclamation?.department}</SoftTypography>
                                                </p>

                                                <p className="text-sm m-0 mb-0">
                                                    <SoftTypography variant="caption"><strong>{t('Prioritat')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption">{reclamation?.priority}</SoftTypography>
                                                </p>

                                                <p className="text-sm m-0 mb-0">
                                                    <SoftTypography variant="caption"><strong>{t('Erstellt von')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption">{reclamation?.user?.name}</SoftTypography>
                                                </p>
                                                <p className="text-sm m-0 mb-0">
                                                    <SoftTypography variant="caption"><strong>{t('Leitstand')}: </strong></SoftTypography>
                                                    <SoftTypography variant="caption">{reclamation?.dc_freigeben == 1 ? t('DC Approved') : '--'}</SoftTypography>
                                                </p>

                                                <p className="text-sm mb-0">
                                                    <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography> <SoftTypography variant="caption">{reclamation?.status == 1? t('Erfolgreich') : t('Nicht Erfolgreich')}</SoftTypography>
                                                </p> 

                                                {reclamation?.products && JSON.parse(reclamation?.products).map(function (product, i) {
                                                    return (
                                                        <p className="text-sm m-0 mb-0">
                                                            <SoftTypography variant="caption"><strong>{t('Product')}: </strong></SoftTypography>
                                                            <SoftTypography variant="caption">{product?.quantity} Stück. {product?.title}</SoftTypography>
                                                        </p>

                                                    );
                                                })}

                                                {reclamation.reclamation_plan && reclamation.reclamation_plan.map((reclamationplan) => (
                                                    <>
                                                        {reclamationplan.email_trans && reclamationplan.email_trans.map((email_tran) => (
                                                            <>

                                                                {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                                                    <>
                                                                        {email ? (
                                                                            <>
                                                                                <p className="text-sm m-0 mb-1">
                                                                                    <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                                                                    <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                                                                </p>
                                                                                <p className="text-sm m-0 mb-1">
                                                                                    <SoftTypography variant="caption">{email}</SoftTypography>
                                                                                </p>
                                                                            </>
                                                                        ) : ''}

                                                                    </>
                                                                ))}
                                                            </>
                                                        ))}
                                                    </>
                                                ))}

                                            </div>
                                        </div>
                                    ) : ''}
                                </>
                            ))}


                            {/*data.project.fertigmeldung && data.project.fertigmeldung.map((fertigmeldung, index) => {
                                return (
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <i className="ni ni-bell-55 text-success text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">{'Fertigmeldung'}</h6>
                                            <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(fertigmeldung.created_at, "dd.mm.yyyy")}</p>

                                            <p className="text-sm m-0">
                                                <SoftTypography variant="caption"><strong>{t('Fertigmeldung')}: </strong></SoftTypography>
                                                <SoftTypography variant="caption">{fertigmeldung?.status == 0 ? t('Approve') : t('Approve')}</SoftTypography>
                                            </p>

                                            <p className="text-sm m-0 mb-0">
                                                <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                                <SoftTypography variant="caption">{dateFormat(fertigmeldung.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                            </p>
                                            <p className="text-sm m-0 mb-1">
                                                <SoftTypography variant="caption">{'fertigmeldung@solacloud.de'}</SoftTypography>
                                            </p>

                                        </div>
                                    </div>
                                )
                            })*/}

                            {data?.project?.fertigmeldung[0] ? (
                                <div className="timeline-block mb-3">
                                    <span className="timeline-step">
                                        <i className="ni ni-bell-55 text-success text-gradient" />
                                    </span>
                                    <div className="timeline-content">
                                        <h6 className="text-dark text-sm font-weight-bold mb-0">{'Fertigmeldung'}</h6>
                                        <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(data?.project?.fertigmeldung[0].created_at, "dd.mm.yyyy")}</p>

                                        <p className="text-sm m-0">
                                            <SoftTypography variant="caption"><strong>{t('Fertigmeldung')}: </strong></SoftTypography>
                                            <SoftTypography variant="caption">{data?.project?.fertigmeldung[0]?.status == 0 ? t('Approve') : t('Approve')}</SoftTypography>
                                        </p>

                                        <p className="text-sm m-0 mb-0">
                                            <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                            <SoftTypography variant="caption">{dateFormat(data?.project?.fertigmeldung[0].created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                        </p>
                                        <p className="text-sm m-0 mb-1">
                                            <SoftTypography variant="caption">{'fertigmeldung@solacloud.de'}</SoftTypography>
                                        </p>

                                    </div>
                                </div>
                            ) : ''}

                        </div>
                    </div>
                </div>
            ) : <></>}
        </>
    )
}

export default TimelineNew;